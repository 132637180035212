import React from "react";
import "../App.scss";
import { contact } from "../Content.js";

function Coffee() {
  return (
    <div id="coffee" className="elena-font-black" alt={contact.coffee}>
      <svg viewBox="0 0 1173 200">
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Artboard-Copy"
            transform="translate(0.000000, -1760.000000)"
            fill="#313131"
          >
            <g id="Group-2" transform="translate(0.000000, 1760.000000)">
              <path
                d="M685,69.8181818 L685,96 L589,96 L589,0 L685,0 L685,26.1818182 L632.636364,26.1818182 L632.636364,34.9090909 L685,34.9090909 L685,61.0909091 L632.636364,61.0909091 L632.636364,69.8181818 L685,69.8181818 Z"
                id="Combined-Shape-Copy-88"
              ></path>
              <path
                d="M1034,173.818182 L1034,200 L938,200 L938,104 L1034,104 L1034,130.181818 L981.636364,130.181818 L981.636364,138.909091 L1034,138.909091 L1034,165.090909 L981.636364,165.090909 L981.636364,173.818182 L1034,173.818182 Z"
                id="Combined-Shape-Copy-99"
              ></path>
              <path
                d="M1138,173.818182 L1138,200 L1042,200 L1042,104 L1138,104 L1138,130.181818 L1085.63636,130.181818 L1085.63636,138.909091 L1138,138.909091 L1138,165.090909 L1085.63636,165.090909 L1085.63636,173.818182 L1138,173.818182 Z"
                id="Combined-Shape-Copy-100"
              ></path>
              <path
                d="M789,69.8181818 L789,96 L693,96 L693,0 L789,0 L789,26.1818182 L736.636364,26.1818182 L736.636364,34.9090909 L789,34.9090909 L789,61.0909091 L736.636364,61.0909091 L736.636364,69.8181818 L789,69.8181818 Z"
                id="Combined-Shape-Copy-89"
              ></path>
              <path
                d="M200,69.8181818 L200,96 L104,96 L104,0 L200,0 L200,26.1818182 L147.636364,26.1818182 L147.636364,34.9090909 L200,34.9090909 L200,61.0909091 L147.636364,61.0909091 L147.636364,69.8181818 L200,69.8181818 Z"
                id="Combined-Shape-Copy-84"
              ></path>
              <path
                d="M773,200 L730,200 L730,104 L826,104 L826,131 L773,131 L773,139 L826,139 L826,165 L773,165 L773,200 Z"
                id="Combined-Shape-Copy-97"
              ></path>
              <path
                d="M877,200 L834,200 L834,104 L930,104 L930,131 L877,131 L877,139 L930,139 L930,165 L877,165 L877,200 Z"
                id="Combined-Shape-Copy-98"
              ></path>
              <path
                d="M43,200 L0,200 L0,104 L96,104 L96,131 L43,131 L43,139 L96,139 L96,165 L43,165 L43,200 Z"
                id="Combined-Shape-Copy-91"
              ></path>
              <rect
                id="Rectangle-Copy-35"
                x="310"
                y="0"
                width="10"
                height="18"
              ></rect>
              <path
                d="M304,165 L304,200 L263,200 L263,180 L254,180 L254,200 L208,200 L208,104 L304,104 L304,156 L295,156 L295,165 L304,165 Z M252,139 L252,148 L261,148 L261,139 L252,139 Z"
                id="Combined-Shape-Copy-93"
              ></path>
              <path
                d="M96,34.9090909 L96,69.8181818 L69.8181818,69.8181818 L69.8181818,0 L96,0 L96,34.9090909 L96,96 L0,96 L0,0 L96,0 L96,34.9090909 Z"
                id="Combined-Shape-Copy-83"
              ></path>
              <path
                d="M365,138.909091 L417.363636,138.909091 L417.363636,130.181818 L365,130.181818 L365,104 L461,104 L461,200 L365,200 L365,138.909091 Z M408.636364,165.090909 L408.636364,173.818182 L417.363636,173.818182 L417.363636,165.090909 L408.636364,165.090909 Z"
                id="Combined-Shape-Copy-94"
              ></path>
              <path
                d="M867,96 L797,96 L797,61 L797,26 L823,26 L823,96 L797,96 L797,61 L797,0 L893,0 L893,26 L867,26 L867,96 Z"
                id="Combined-Shape-Copy-90"
              ></path>
              <path
                d="M276,96 L206,96 L206,61 L206,26 L232,26 L232,96 L206,96 L206,61 L206,0 L302,0 L302,26 L276,26 L276,96 Z"
                id="Combined-Shape-Copy-85"
              ></path>
              <path
                d="M555.5,96 L555.5,70.5 L546.5,70.5 L546.5,96 L519.5,96 L519.5,70.5 L510.5,70.5 L510.5,96 L485,96 L485,-8.81745695e-15 L529,-7.34788079e-16 L529,9 L538,9 L538,0 L581,8.81745695e-15 L581,96 L555.5,96 Z"
                id="Combined-Shape-Copy-87"
              ></path>
              <path
                d="M618,147.636364 L565.636364,147.636364 L565.636364,156.363636 L618,156.363636 L618,200 L522,200 L522,104 L618,104 L618,147.636364 Z"
                id="Combined-Shape-Copy-95"
              ></path>
              <path
                d="M626,104 L722,104 L722,200 L626,200 L626,104 Z M669.701493,147.701493 L669.701493,156.298507 L678.298507,156.298507 L678.298507,147.701493 L669.701493,147.701493 Z"
                id="Combined-Shape-Copy-96"
              ></path>
              <path
                d="M104,104 L200,104 L200,200 L104,200 L104,104 Z M147.701493,147.701493 L147.701493,156.298507 L156.298507,156.298507 L156.298507,147.701493 L147.701493,147.701493 Z"
                id="Combined-Shape-Copy-92"
              ></path>
              <path
                d="M424,26 L372,26 L372,35 L424,35 L424,96 L328,96 L328,69.8181818 L380.363636,69.8181818 L380.363636,61.0909091 L328,61.0909091 L328,0 L424,0 L424,26 Z"
                id="Combined-Shape-Copy-86"
              ></path>
              <path
                d="M1173,160.0625 L1173,173 L1146,173 L1146,138.5 L1155,138.5 L1155,112.625 L1146,112.625 L1146,104 L1173,104 L1173,160.0625 L1173,147.125 L1155,147.125 L1155,173 L1173,173 L1173,160.0625 Z M1146,192 L1154,192 L1154,200 L1146,200 L1146,192 Z"
                id="Combined-Shape-Copy-101"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default Coffee;
