import React from "react";
import "../App.scss";

function Fox() {
  return (
    <>
    <div id="fox-anim">
      <div id="fox" className="images-loaded-container"></div>
      </div>
    </>
  );
}

export default Fox;
