import React from "react";
import "../App.scss";
import { motorola5GBattleProject } from "../Content.js";

function Motorola5GBattleHeadline() {
  return (
    <div id="motorola-5g-battle-headline" alt={motorola5GBattleProject.headlineMain1} className="elena-font-black">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1372.54 202.05">
        <path d="M368.52,151.05v-45h-96v96h96v-43h-9v-8Zm-43,24h-8v-8h8Zm0-34h-8v-8h8Z" />
        <path d="M728,0V26.18h52.36V34.9H728V96h96V0Zm52.36,69.81h-8.73V61.09h8.73Z" />
        <path d="M377,106.05v26.18h52.36V141H377v61.1h96v-96Zm52.36,69.81h-8.73v-8.72h8.73Z" />
        <polygon points="304 0 304 26 278 26 278 96 234 96 234 26 208 26 208 0 304 0" />
        <polygon points="576.52 106.05 576.52 132.05 550.52 132.05 550.52 202.05 506.52 202.05 506.52 132.05 480.52 132.05 480.52 106.05 576.52 106.05" />
        <polygon points="680.52 106.05 680.52 132.05 654.52 132.05 654.52 202.05 610.52 202.05 610.52 132.05 584.52 132.05 584.52 106.05 680.52 106.05" />
        <path d="M512,52V0H416V96h46V76h9V96h41V61h-9V52Zm-43-8h-9V35h9Z" />
        <polygon points="96 0 96 96 70.5 96 70.5 70.5 61.5 70.5 61.5 96 34.5 96 34.5 70.5 25.5 70.5 25.5 96 0 96 0 0 44 0 44 9 53 9 53 0 96 0" />
        <polygon points="720 69.81 720 96 624 96 624 0 693.82 0 693.82 69.81 720 69.81" />
        <polygon points="785 175.86 785 202.05 689 202.05 689 106.05 758.82 106.05 758.82 175.86 785 175.86" />
        <polygon points="147.5 141.55 147.5 167.55 156.5 167.55 156.5 149.68 200 149.68 200 202.05 104 202.05 104 106.05 200 106.05 200 140.95 147.63 140.95 147.63 141.55 147.5 141.55" />
        <polygon points="836.63 132.23 836.63 140.95 889 140.95 889 167.14 836.63 167.14 836.63 175.86 889 175.86 889 202.05 793 202.05 793 106.05 889 106.05 889 132.23 836.63 132.23" />
        <polygon points="1008.04 167.14 1008.04 175.87 1060.4 175.87 1060.4 202.05 964.4 202.05 964.4 140.96 1016.77 140.96 1016.77 132.23 964.4 132.23 964.4 106.05 1060.4 106.05 1060.4 167.14 1008.04 167.14" />
        <path d="M1068.54,106.05v96h96v-96Zm52.36,52.37h-8.72v-8.73h8.72Z" />
        <polygon points="1216.04 167.14 1216.04 175.87 1268.4 175.87 1268.4 202.05 1172.4 202.05 1172.4 140.96 1224.77 140.96 1224.77 132.23 1172.4 132.23 1172.4 106.05 1268.4 106.05 1268.4 167.14 1216.04 167.14" />
        <path d="M1276.54,106.05v96h96v-96Zm52.36,52.37h-8.72v-8.73h8.72Z" />
        <path d="M104,0V96h96V0Zm52.36,52.37h-8.72V43.64h8.72Z" />
        <path d="M312,0V96h96V0Zm52.36,52.37h-8.72V43.64h8.72Z" />
        <path d="M520,0V96h96V0Zm52.36,52.37h-8.72V43.64h8.72Z" />
        <polygon points="43.77 132.23 43.77 140.96 96.13 140.96 96.13 202.05 0.13 202.05 0.13 175.87 52.5 175.87 52.5 167.14 0.13 167.14 0.13 106.05 96.13 106.05 96.13 132.23 43.77 132.23" />
      </svg>
    </div>
  );
}

export default Motorola5GBattleHeadline;
