import React from "react";
import "../App.scss";
import { motorola5GBattleProject } from "../Content.js";

function Motorola5GBattleHeadline3Lines() {
  return (
    <div
      id="motorola-5g-battle-headline-3-lines"
      alt={motorola5GBattleProject.headlineMain1}
      className="elena-font-black"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 928.6 307.05">
        <path
          class="cls-1"
          d="M408.12,151.05v-45h-96v96h96v-43h-9v-8Zm-43,24h-8v-8h8Zm0-34h-8v-8h8Z"
        />
        <path
          class="cls-1"
          d="M728.6,0V26.18H781V34.9H728.6V96h96V0ZM781,69.81h-8.73V61.09H781Z"
        />
        <path
          class="cls-1"
          d="M416.6,106.05v26.18H469V141H416.6v61.1h96v-96ZM469,175.86h-8.73v-8.72H469Z"
        />
        <polygon
          class="cls-2"
          points="304.6 0 304.6 26 278.6 26 278.6 96 234.59 96 234.59 26 208.59 26 208.59 0 304.6 0"
        />
        <polygon
          class="cls-2"
          points="616.12 106.05 616.12 132.05 590.12 132.05 590.12 202.05 546.12 202.05 546.12 132.05 520.12 132.05 520.12 106.05 616.12 106.05"
        />
        <polygon
          class="cls-2"
          points="720.12 106.05 720.12 132.05 694.12 132.05 694.12 202.05 650.12 202.05 650.12 132.05 624.12 132.05 624.12 106.05 720.12 106.05"
        />
        <path
          class="cls-2"
          d="M512.6,52V0h-96V96h46V76h9V96h41V61h-9V52Zm-43-8h-9V35h9Z"
        />
        <polygon
          class="cls-2"
          points="96.59 0 96.59 96 71.09 96 71.09 70.5 62.09 70.5 62.09 96 35.09 96 35.09 70.5 26.09 70.5 26.09 96 0.59 96 0.59 0 44.59 0 44.59 9 53.59 9 53.59 0 96.59 0"
        />
        <polygon
          class="cls-2"
          points="720.6 69.81 720.6 96 624.6 96 624.6 0 694.41 0 694.41 69.81 720.6 69.81"
        />
        <polygon
          class="cls-2"
          points="824.6 175.86 824.6 202.05 728.6 202.05 728.6 106.05 798.41 106.05 798.41 175.86 824.6 175.86"
        />
        <polygon
          class="cls-2"
          points="148.09 141.55 148.09 167.55 157.09 167.55 157.09 149.68 200.59 149.68 200.59 202.05 104.59 202.05 104.59 106.05 200.59 106.05 200.59 140.95 148.22 140.95 148.22 141.55 148.09 141.55"
        />
        <polygon
          class="cls-2"
          points="876.23 132.23 876.23 140.95 928.6 140.95 928.6 167.14 876.23 167.14 876.23 175.86 928.6 175.86 928.6 202.05 832.6 202.05 832.6 106.05 928.6 106.05 928.6 132.23 876.23 132.23"
        />
        <polygon
          class="cls-1"
          points="43.64 272.14 43.64 280.87 96 280.87 96 307.05 0 307.05 0 245.96 52.36 245.96 52.36 237.23 0 237.23 0 211.05 96 211.05 96 272.14 43.64 272.14"
        />
        <path
          class="cls-1"
          d="M104.13,211.05v96h96v-96Zm52.36,52.37h-8.72v-8.73h8.72Z"
        />
        <polygon
          class="cls-1"
          points="251.64 272.14 251.64 280.87 304 280.87 304 307.05 208 307.05 208 245.96 260.36 245.96 260.36 237.23 208 237.23 208 211.05 304 211.05 304 272.14 251.64 272.14"
        />
        <path
          class="cls-1"
          d="M312.13,211.05v96h96v-96Zm52.36,52.37h-8.72v-8.73h8.72Z"
        />
        <path class="cls-1" d="M104.6,0V96h96V0ZM157,52.37h-8.73V43.64H157Z" />
        <path class="cls-1" d="M312.6,0V96h96V0ZM365,52.37h-8.73V43.64H365Z" />
        <path class="cls-1" d="M520.6,0V96h96V0ZM573,52.37h-8.73V43.64H573Z" />
        <polygon
          class="cls-1"
          points="44.36 132.23 44.36 140.96 96.72 140.96 96.72 202.05 0.72 202.05 0.72 175.87 53.09 175.87 53.09 167.14 0.72 167.14 0.72 106.05 96.72 106.05 96.72 132.23 44.36 132.23"
        />
      </svg>
    </div>
  );
}

export default Motorola5GBattleHeadline3Lines;
