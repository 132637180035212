import React from "react";
import "../App.scss";

function LogoReact() {
  return (
    <div id="logo-react">
      <svg version="1.1" viewBox="0 0 490.6 436.9">
        <path
          fill="#61DAFB"
          d="M490.6,218.5c0-32.5-40.7-63.3-103.1-82.4c14.4-63.6,8-114.2-20.2-130.4c-6.5-3.8-14-5.6-22.3-5.6V0
	c-13.8,0-29.6,4.9-46.6,14c-17,9.2-35.1,22.5-53.3,39.5c-36.4-33.8-72.4-53.2-99.9-53.2v0.1c-4.2,0-8.2,0.5-12,1.4
	c-3.8,0.9-7.3,2.3-10.6,4.2c-28.1,16.2-34.4,66.7-19.9,130.1C40.5,155.4,0,186.1,0,218.5c0,32.5,40.7,63.3,103.1,82.4
	c-14.4,63.6-8,114.2,20.2,130.4c6.5,3.8,14.1,5.6,22.5,5.6c27.5,0,63.5-19.6,99.9-53.6c36.4,33.8,72.4,53.2,99.9,53.2
	c8.4,0,16-1.8,22.6-5.6c28.1-16.2,34.4-66.7,19.9-130.1C450.1,281.7,490.6,250.9,490.6,218.5z M344.9,22.3L344.9,22.3
	c4.6,0.1,8.3,1,11.4,2.7c13.6,7.8,19.5,37.5,14.9,75.7c-1.1,9.4-2.9,19.3-5.1,29.4c-19.6-4.8-41-8.5-63.5-10.9
	c-13.5-18.5-27.5-35.3-41.6-50C293.5,39,324.1,22.3,344.9,22.3z M156.7,269.7c4.7,8,9.5,15.8,14.4,23.4c-14.2-2.1-27.9-4.7-41-7.9
	c3.7-12.9,8.3-26.2,13.5-39.5C147.7,253.7,152,261.7,156.7,269.7z M129.8,151.8c13.1-3.1,26.9-5.8,41.2-7.9
	c-4.9,7.7-9.8,15.6-14.4,23.7c-4.6,8-8.9,16-13,24C138.2,178.2,133.6,164.8,129.8,151.8z M155.3,218.8c6.2-13.4,13.2-26.8,20.7-39.9
	c7.8-13.5,15.8-26.3,24.1-38.2c14.9-1.3,30-2,45.2-2c15.1,0,30.2,0.7,45,1.9c8.3,11.9,16.4,24.6,24.2,38
	c7.6,13.1,14.5,26.4,20.8,39.8c-6.3,13.4-13.2,26.8-20.7,39.9c-7.8,13.5-15.8,26.3-24.1,38.2c-14.9,1.3-30,2-45.2,2
	c-15.1,0-30.2-0.7-45-1.9c-8.3-11.9-16.4-24.6-24.2-38C168.5,245.5,161.6,232.2,155.3,218.8z M333.9,269.4c4.6-8,8.9-16.1,13-24.1
	c5.4,13.4,10,26.8,13.8,39.8c-13.1,3.2-26.9,5.9-41.2,8C324.4,285.4,329.3,277.5,333.9,269.4z M333.8,167.3
	c-4.6-8-9.5-15.8-14.4-23.4c14.2,2.1,27.9,4.7,41,7.9c-3.7,12.9-8.3,26.2-13.5,39.5C342.8,183.3,338.5,175.3,333.8,167.3z
	 M272.8,117c-9-0.4-18.2-0.7-27.5-0.7c-9.4,0-18.7,0.2-27.8,0.7c9-11.7,18.3-22.4,27.5-32C254.3,94.6,263.6,105.3,272.8,117z
	 M133.7,25.4c3-1.8,6.9-2.6,11.5-2.6v-0.1c20.7,0,51.4,16.5,84,46.6c-14,14.7-28,31.4-41.3,49.9c-22.6,2.4-44,6.1-63.6,11
	c-2.3-10-4-19.7-5.2-29C114.4,63,120.2,33.3,133.7,25.4z M108.5,279.3c-9.9-3.1-19.3-6.5-28-10.2c-35.4-15.1-58.3-34.9-58.3-50.6
	c0-15.7,22.9-35.6,58.3-50.6c8.6-3.7,18-7,27.7-10.1c5.7,19.6,13.2,40,22.5,60.9C121.5,239.5,114.1,259.8,108.5,279.3z M145.6,414.7
	c-4.5-0.1-8.3-1-11.3-2.7c-13.6-7.8-19.5-37.5-14.9-75.7c1.1-9.4,2.9-19.3,5.1-29.4c19.6,4.8,41,8.5,63.5,10.9
	c13.5,18.5,27.5,35.3,41.6,50C197,398.1,166.4,414.7,145.6,414.7z M245.5,352c-9.3-9.6-18.6-20.3-27.8-32c9,0.4,18.2,0.7,27.5,0.7
	c9.4,0,18.7-0.2,27.8-0.7C264,331.7,254.7,342.4,245.5,352z M356.9,411.6c-3,1.8-6.9,2.6-11.5,2.6c-20.7,0-51.4-16.5-84-46.6
	c14-14.7,28-31.4,41.3-49.9c22.6-2.4,44-6.1,63.6-11c2.3,10.1,4.1,19.8,5.2,29.1C376.2,374,370.4,403.7,356.9,411.6z M410,269.1
	c-8.6,3.7-18,7-27.7,10.1c-5.7-19.6-13.2-40-22.5-60.9c9.2-20.8,16.6-41.1,22.2-60.6c9.9,3.1,19.3,6.5,28.1,10.2
	c35.4,15.1,58.3,34.9,58.3,50.6C468.3,234.2,445.4,254.1,410,269.1z"
        />
        <ellipse
          transform="matrix(0.9239 -0.3827 0.3827 0.9239 -64.9516 110.4663)"
          fill="#61DAFB"
          cx="245.2"
          cy="218.5"
          rx="45.7"
          ry="45.7"
        />
      </svg>
    </div>
  );
}

export default LogoReact;
