import React from "react";
import "../App.scss";
import { aPitProject } from "../Content.js";

function APitPhase1() {
  return (
    <div id="apit-phase-1" className="phase elena-font-yellow" alt={aPitProject.headlinePhase1}>
      <svg viewBox="0 0 997 200" version="1.1">
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Artboard-Copy"
            transform="translate(0.000000, -593.000000)"
            fill="#313131"
          >
            <g id="Group-2" transform="translate(-2.000000, 593.000000)">
              <path
                d="M514,61.0909091 L461.636364,61.0909091 L461.636364,69.8181818 L514,69.8181818 L514,96 L418,96 L418,0 L514,0 L514,26.1818182 L461.636364,26.1818182 L461.636364,34.9090909 L514,34.9090909 L514,61.0909091 Z"
                id="Combined-Shape-Copy-18"
              ></path>
              <path
                d="M200,173.818182 L200,200 L104,200 L104,104 L200,104 L200,130.181818 L147.636364,130.181818 L147.636364,138.909091 L200,138.909091 L200,165.090909 L147.636364,165.090909 L147.636364,173.818182 L200,173.818182 Z"
                id="Combined-Shape-Copy-21"
              ></path>
              <path
                d="M304,149 L295,149 L295,157 L304,157 L304,200 L208,200 L208,104 L304,104 L304,149 Z M253,131 L253,139 L261,139 L261,131 L253,131 Z M253,165 L253,173 L261,173 L261,165 L253,165 Z"
                id="Combined-Shape"
              ></path>
              <path
                d="M546.181818,200 L520,200 L520,104 L581.090909,104 L581.090909,130.181818 L589.818182,130.181818 L589.818182,104 L616,104 L616,200 L554.909091,200 L554.909091,173.818182 L546.181818,173.818182 L546.181818,200 Z"
                id="Combined-Shape-Copy-24"
              ></path>
              <path
                d="M807.181818,200 L781,200 L781,104 L842.090909,104 L842.090909,130.181818 L850.818182,130.181818 L850.818182,104 L877,104 L877,200 L815.909091,200 L815.909091,173.818182 L807.181818,173.818182 L807.181818,200 Z"
                id="Combined-Shape-Copy-26"
              ></path>
              <path
                d="M416,138.909091 L468.363636,138.909091 L468.363636,130.181818 L416,130.181818 L416,104 L512,104 L512,200 L416,200 L416,138.909091 Z M459.636364,165.090909 L459.636364,173.818182 L468.363636,173.818182 L468.363636,165.090909 L459.636364,165.090909 Z"
                id="Combined-Shape-Copy-23"
              ></path>
              <path
                d="M210,34.9090909 L262.363636,34.9090909 L262.363636,26.1818182 L210,26.1818182 L210,0 L306,0 L306,96 L210,96 L210,34.9090909 Z M253.636364,61.0909091 L253.636364,69.8181818 L262.363636,69.8181818 L262.363636,61.0909091 L253.636364,61.0909091 Z"
                id="Combined-Shape-Copy-16"
              ></path>
              <path
                d="M773,104 L773,200 L728,200 L728,104 L773,104 Z"
                id="Combined-Shape-Copy-25"
              ></path>
              <g
                id="Group-Copy-3"
                transform="translate(624.000000, 104.000000)"
              >
                <path
                  d="M76.6095237,96 L0,96 L0,0 L71.7541126,0 L96,24.2458874 L96,76.6095237 L76.6095237,96 Z M44,44 L44,53 L53,53 L53,44 L44,44 Z"
                  id="Combined-Shape"
                ></path>
              </g>
              <path
                d="M96,165 L96,200 L55,200 L55,180 L46,180 L46,200 L0,200 L0,104 L96,104 L96,156 L87,156 L87,165 L96,165 Z M44,139 L44,148 L53,148 L53,139 L44,139 Z"
                id="Combined-Shape-Copy-20"
              ></path>
              <path
                d="M408,165 L408,200 L367,200 L367,180 L358,180 L358,200 L312,200 L312,104 L408,104 L408,156 L399,156 L399,165 L408,165 Z M356,139 L356,148 L365,148 L365,139 L356,139 Z"
                id="Combined-Shape-Copy-22"
              ></path>
              <path
                d="M98,72.5 L71.5,72.5 L71.5,96 L2,96 L2,0 L98,0 L98,72.5 Z M46,35 L46,44 L55,44 L55,35 L46,35 Z"
                id="Combined-Shape-Copy-14"
              ></path>
              <path
                d="M410,26 L358,26 L358,35 L410,35 L410,96 L314,96 L314,69.8181818 L366.363636,69.8181818 L366.363636,61.0909091 L314,61.0909091 L314,0 L410,0 L410,26 Z"
                id="Combined-Shape-Copy-17"
              ></path>
              <path
                d="M928.636364,139.5 L928.5,139.5 L928.5,165.5 L937.5,165.5 L937.5,147.636364 L981,147.636364 L981,200 L885,200 L885,104 L981,104 L981,138.909091 L928.636364,138.909091 L928.636364,139.5 Z"
                id="Combined-Shape-Copy-27"
              ></path>
              <path
                d="M149.636364,96 L106,96 L106,0 L149.636364,0 L149.636364,26.1818182 L158.363636,26.1818182 L158.363636,1.31450406e-13 L202,0 L202,96 L158.363636,96 L158.363636,69.8181818 L149.636364,69.8181818 L149.636364,96 Z"
                id="Combined-Shape-Copy-15"
              ></path>
              <path
                d="M660,34.9090909 L660,69.8181818 L633.818182,69.8181818 L633.818182,0 L660,0 L660,34.9090909 L660,96 L564,96 L564,0 L660,0 L660,34.9090909 Z"
                id="Combined-Shape-Copy-19"
                transform="translate(612.000000, 48.000000) rotate(-180.000000) translate(-612.000000, -48.000000) "
              ></path>
              <rect id="Rectangle" x="668" y="86" width="10" height="10"></rect>
              <rect
                id="Rectangle"
                x="989"
                y="190"
                width="10"
                height="10"
              ></rect>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default APitPhase1;
