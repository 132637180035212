import React from "react";
import "../App.scss";

function Year({ year }) {
  return (
    <div className="year-container">
      {(() => {
        switch (year) {
          case "2018":
            return (
              <svg viewBox="0 0 573 59" version="1.1">
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Artboard-Copy-3"
                    transform="translate(-10.000000, -17.000000)"
                    fill="#313131"
                  >
                    <g id="Group" transform="translate(10.000000, 17.000000)">
                      <path
                        d="M59,37.5454545 L26.8181818,37.5454545 L26.8181818,42.9090909 L59,42.9090909 L59,59 L0,59 L0,21.4545455 L32.1818182,21.4545455 L32.1818182,16.0909091 L0,16.0909091 L0,0 L59,0 L59,37.5454545 Z"
                        id="Combined-Shape"
                      ></path>
                      <path
                        d="M380,37.5454545 L347.818182,37.5454545 L347.818182,42.9090909 L380,42.9090909 L380,59 L321,59 L321,21.4545455 L353.181818,21.4545455 L353.181818,16.0909091 L321,16.0909091 L321,0 L380,0 L380,37.5454545 Z"
                        id="Combined-Shape"
                      ></path>
                      <path
                        d="M129,37.5454545 L129,16.0909091 L145.090909,16.0909091 L145.090909,59 L129,59 L129,37.5454545 L129,0 L188,0 L188,59 L129,59 L129,37.5454545 Z"
                        id="Combined-Shape"
                      ></path>
                      <path
                        d="M450,37.5454545 L450,16.0909091 L466.090909,16.0909091 L466.090909,59 L450,59 L450,37.5454545 L450,0 L509,0 L509,59 L450,59 L450,37.5454545 Z"
                        id="Combined-Shape"
                      ></path>
                      <path
                        d="M514,42.9090909 L546.181818,42.9090909 L546.181818,37.5454545 L514,37.5454545 L514,0 L573,0 L573,59 L514,59 L514,42.9090909 Z M540.818182,16.0909091 L540.818182,21.4545455 L546.181818,21.4545455 L546.181818,16.0909091 L540.818182,16.0909091 Z"
                        id="Combined-Shape"
                      ></path>
                      <path
                        d="M252,26.8181818 L246.636364,26.8181818 L246.636364,32.1818182 L252,32.1818182 L252,59 L193,59 L193,32.1818182 L198.363636,32.1818182 L198.363636,26.8181818 L193,26.8181818 L193,0 L252,0 L252,26.8181818 Z M219.818182,16.0909091 L219.818182,21.4545455 L225.181818,21.4545455 L225.181818,16.0909091 L219.818182,16.0909091 Z M219.818182,37.5454545 L219.818182,42.9090909 L225.181818,42.9090909 L225.181818,37.5454545 L219.818182,37.5454545 Z"
                        id="Combined-Shape"
                      ></path>
                      <rect
                        id="Rectangle-Copy-29"
                        x="257"
                        y="21"
                        width="59"
                        height="16"
                      ></rect>
                      <path
                        d="M64,0 L123,0 L123,59 L64,59 L64,0 Z M90.8181818,26.8181818 L90.8181818,32.1818182 L96.1818182,32.1818182 L96.1818182,26.8181818 L90.8181818,26.8181818 Z"
                        id="Combined-Shape"
                      ></path>
                      <path
                        d="M386,0 L445,0 L445,59 L386,59 L386,0 Z M412.818182,26.8181818 L412.818182,32.1818182 L418.181818,32.1818182 L418.181818,26.8181818 L412.818182,26.8181818 Z"
                        id="Combined-Shape"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            );
          case "2017":
            return (
              <svg viewBox="0 0 252 59" version="1.1">
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Artboard-Copy-3"
                    transform="translate(-10.000000, -86.000000)"
                    fill="#313131"
                  >
                    <g id="Group-2" transform="translate(10.000000, 86.000000)">
                      <path
                        d="M59,42.9090909 L59,59 L0,59 L0,21.4545455 L32.1818182,21.4545455 L32.1818182,16.0909091 L0,16.0909091 L0,0 L59,0 L59,37.5454545 L26.8181818,37.5454545 L26.8181818,42.9090909 L59,42.9090909 Z"
                        id="Combined-Shape"
                      ></path>
                      <path
                        d="M129,37.5454545 L129,16.0909091 L145.090909,16.0909091 L145.090909,59 L129,59 L129,37.5454545 L129,0 L188,0 L188,59 L129,59 L129,37.5454545 Z"
                        id="Combined-Shape"
                      ></path>
                      <path
                        d="M209.090909,26.8181818 L193,26.8181818 L193,0 L246.636364,0 L246.636364,26.8181818 L252,26.8181818 L252,42.9090909 L246.636364,42.9090909 L246.636364,59 L193,59 L193,37.5454545 L193,16.0909091 L209.090909,16.0909091 L209.090909,26.8181818 Z M209.090909,42.9090909 L193,42.9090909 L193,59 L209.090909,59 L209.090909,42.9090909 Z"
                        id="Combined-Shape"
                      ></path>
                      <path
                        d="M64,0 L123,0 L123,59 L64,59 L64,0 Z M90.8181818,26.8181818 L90.8181818,32.1818182 L96.1818182,32.1818182 L96.1818182,26.8181818 L90.8181818,26.8181818 Z"
                        id="Combined-Shape"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            );
          case "2016":
            return (
              <svg viewBox="0 0 252 59" version="1.1">
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Artboard-Copy-3"
                    transform="translate(-10.000000, -155.000000)"
                    fill="#313131"
                  >
                    <g
                      id="Group-3"
                      transform="translate(10.000000, 155.000000)"
                    >
                      <path
                        d="M59,42.9090909 L59,59 L0,59 L0,21.4545455 L32.1818182,21.4545455 L32.1818182,16.0909091 L0,16.0909091 L0,0 L59,0 L59,37.5454545 L26.8181818,37.5454545 L26.8181818,42.9090909 L59,42.9090909 Z"
                        id="Combined-Shape"
                      ></path>
                      <path
                        d="M129,37.5454545 L129,16.0909091 L145.090909,16.0909091 L145.090909,59 L129,59 L129,37.5454545 L129,0 L188,0 L188,59 L129,59 L129,37.5454545 Z"
                        id="Combined-Shape"
                      ></path>
                      <path
                        d="M252,16.0909091 L219.818182,16.0909091 L219.818182,21.4545455 L252,21.4545455 L252,59 L193,59 L193,1.20792265e-13 L252,1.27897692e-13 L252,16.0909091 Z M225.181818,42.9090909 L225.181818,37.5454545 L219.818182,37.5454545 L219.818182,42.9090909 L225.181818,42.9090909 Z"
                        id="Combined-Shape"
                      ></path>
                      <path
                        d="M64,0 L123,0 L123,59 L64,59 L64,0 Z M90.8181818,26.8181818 L90.8181818,32.1818182 L96.1818182,32.1818182 L96.1818182,26.8181818 L90.8181818,26.8181818 Z"
                        id="Combined-Shape"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            );
          case "2015":
            return (
              <svg viewBox="0 0 252 59" version="1.1">
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Artboard-Copy-3"
                    transform="translate(-10.000000, -225.000000)"
                    fill="#313131"
                  >
                    <g
                      id="Group-4"
                      transform="translate(10.000000, 225.000000)"
                    >
                      <path
                        d="M59,42.9090909 L59,59 L0,59 L0,21.4545455 L32.1818182,21.4545455 L32.1818182,16.0909091 L0,16.0909091 L0,0 L59,0 L59,37.5454545 L26.8181818,37.5454545 L26.8181818,42.9090909 L59,42.9090909 Z"
                        id="Combined-Shape"
                      ></path>
                      <path
                        d="M193,37.5454545 L193,0 L252,0 L252,16.0909091 L219.818182,16.0909091 L219.818182,21.4545455 L252,21.4545455 L252,59 L193,59 L193,42.9090909 L225.181818,42.9090909 L225.181818,37.5454545 L193,37.5454545 Z"
                        id="Combined-Shape"
                      ></path>
                      <path
                        d="M129,37.5454545 L129,16.0909091 L145.090909,16.0909091 L145.090909,59 L129,59 L129,37.5454545 L129,0 L188,0 L188,59 L129,59 L129,37.5454545 Z"
                        id="Combined-Shape"
                      ></path>
                      <path
                        d="M64,0 L123,0 L123,59 L64,59 L64,0 Z M90.8181818,26.8181818 L90.8181818,32.1818182 L96.1818182,32.1818182 L96.1818182,26.8181818 L90.8181818,26.8181818 Z"
                        id="Combined-Shape"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            );
          case "2014":
            return (
              <svg viewBox="0 0 252 59" version="1.1">
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Artboard-Copy-3"
                    transform="translate(-10.000000, -294.000000)"
                    fill="#313131"
                  >
                    <g
                      id="Group-5"
                      transform="translate(10.000000, 294.000000)"
                    >
                      <path
                        d="M59,42.9090909 L59,59 L0,59 L0,21.4545455 L32.1818182,21.4545455 L32.1818182,16.0909091 L0,16.0909091 L0,0 L59,0 L59,37.5454545 L26.8181818,37.5454545 L26.8181818,42.9090909 L59,42.9090909 Z"
                        id="Combined-Shape"
                      ></path>
                      <path
                        d="M129,37.5454545 L129,16.0909091 L145.090909,16.0909091 L145.090909,59 L129,59 L129,37.5454545 L129,0 L188,0 L188,59 L129,59 L129,37.5454545 Z"
                        id="Combined-Shape"
                      ></path>
                      <path
                        d="M193,42.9090909 L193,0 L219.818182,0 L219.818182,16.0909091 L225.181818,16.0909091 L225.181818,-3.19744231e-14 L252,0 L252,59 L209.090909,59 L209.090909,42.9090909 L193,42.9090909 Z"
                        id="Combined-Shape"
                      ></path>
                      <path
                        d="M64,0 L123,0 L123,59 L64,59 L64,0 Z M90.8181818,26.8181818 L90.8181818,32.1818182 L96.1818182,32.1818182 L96.1818182,26.8181818 L90.8181818,26.8181818 Z"
                        id="Combined-Shape"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            );
          default:
            return "";
        }
      })()}
    </div>
  );
}

export default Year;
