import React from "react";
import "../App.scss";
import { introText } from "../Content.js";

function Logo() {
  return (
    <div id="logo" className="elena-font-black">
      <span id="profession">{introText.myTitle}</span>
      <svg viewBox="0 0 1082 199">
        <g
          fillRule="evenodd"
        >
          <path
            d="M950.882251,199 L883,199 L883,103 L926.636364,103 L926.636364,155.363636 L935.363636,155.363636 L935.363636,103 L979,103 L979,170.882251 L950.882251,199 Z"
            id="Combined-Shape"
            fill="#ffffff"
          ></path>
          <path
            d="M96,61.0909091 L43.6363636,61.0909091 L43.6363636,69.8181818 L96,69.8181818 L96,96 L0,96 L0,0 L96,0 L96,26.1818182 L43.6363636,26.1818182 L43.6363636,34.9090909 L96,34.9090909 L96,61.0909091 Z"
            id="Combined-Shape"
            fill="#ffffff"
          ></path>
          <path
            d="M876,172.818182 L876,199 L780,199 L780,103 L876,103 L876,129.181818 L823.636364,129.181818 L823.636364,137.909091 L876,137.909091 L876,164.090909 L823.636364,164.090909 L823.636364,172.818182 L876,172.818182 Z"
            id="Combined-Shape"
            fill="#ffffff"
          ></path>
          <path
            d="M466.363636,103 L510,103 L510,170.882251 L481.882251,199 L414,199 L414,103 L457.636364,103 L457.636364,155.363636 L466.363636,155.363636 L466.363636,103 Z"
            id="Combined-Shape"
            fill="#ffffff"
          ></path>
          <path
            d="M52.3636364,103 L96,103 L96,170.882251 L67.882251,199 L0,199 L0,103 L43.6363636,103 L43.6363636,155.363636 L52.3636364,155.363636 L52.3636364,103 Z"
            id="Combined-Shape-Copy"
            fill="#ffffff"
          ></path>
          <path
            d="M302,69.8181818 L302,96 L206,96 L206,0 L302,0 L302,26.1818182 L249.636364,26.1818182 L249.636364,34.9090909 L302,34.9090909 L302,61.0909091 L249.636364,61.0909091 L249.636364,69.8181818 L302,69.8181818 Z"
            id="Combined-Shape"
            fill="#ffffff"
          ></path>
          <path
            d="M336.181818,96 L310,96 L310,0 L371.090909,0 L371.090909,26.1818182 L379.818182,26.1818182 L379.818182,3.41060513e-13 L406,0 L406,96 L344.909091,96 L344.909091,69.8181818 L336.181818,69.8181818 L336.181818,96 Z"
            id="Combined-Shape"
            fill="#ffffff"
          ></path>
          <path
            d="M719.636364,199 L676,199 L676,103 L719.636364,103 L719.636364,129.181818 L728.363636,129.181818 L728.363636,103 L772,103 L772,199 L728.363636,199 L728.363636,172.818182 L719.636364,172.818182 L719.636364,199 Z"
            id="Combined-Shape"
            fill="#ffffff"
          ></path>
          <path
            d="M414,34.9090909 L466.363636,34.9090909 L466.363636,26.1818182 L414,26.1818182 L414,0 L510,0 L510,96 L414,96 L414,34.9090909 Z M457.636364,61.0909091 L457.636364,69.8181818 L466.363636,69.8181818 L466.363636,61.0909091 L457.636364,61.0909091 Z"
            id="Combined-Shape"
            fill="#ffffff"
          ></path>
          <path
            d="M103,103 L199,103 L199,199 L103,199 L103,103 Z M146.636364,146.636364 L146.636364,155.363636 L155.363636,155.363636 L155.363636,146.636364 L146.636364,146.636364 Z"
            id="Combined-Shape"
            fill="#ffffff"
          ></path>
          <path
            d="M310,103 L406,103 L406,199 L310,199 L310,103 Z M353.636364,146.636364 L353.636364,155.363636 L362.363636,155.363636 L362.363636,146.636364 L353.636364,146.636364 Z"
            id="Combined-Shape"
            fill="#ffffff"
          ></path>
          <path
            d="M199,34.9090909 L199,69.8181818 L172.818182,69.8181818 L172.818182,0 L199,0 L199,34.9090909 L199,96 L103,96 L103,0 L199,0 L199,34.9090909 Z"
            id="Combined-Shape"
            fill="#ffffff"
          ></path>
          <path
            d="M302,137.909091 L302,172.818182 L275.818182,172.818182 L275.818182,103 L302,103 L302,137.909091 L302,199 L206,199 L206,103 L302,103 L302,137.909091 Z"
            id="Combined-Shape"
            fill="#ffffff"
          ></path>
          <path
            d="M564,103 L564,199 L519,199 L519,103 L564,103 Z"
            id="Combined-Shape"
            fill="#ffffff"
          ></path>
          <path
            d="M668,146.636364 L615.636364,146.636364 L615.636364,155.363636 L668,155.363636 L668,199 L572,199 L572,103 L668,103 L668,146.636364 Z"
            id="Combined-Shape"
            fill="#ffffff"
          ></path>
          <path
            d="M986,137.909091 L1038.36364,137.909091 L1038.36364,129.181818 L986,129.181818 L986,103 L1082,103 L1082,199 L986,199 L986,137.909091 Z M1029.63636,164.090909 L1029.63636,172.818182 L1038.36364,172.818182 L1038.36364,164.090909 L1029.63636,164.090909 Z"
            id="Combined-Shape"
            fill="#ffffff"
          ></path>
        </g>
      </svg>
    </div>
  );
}

export default Logo;
