import React from "react";
import "../App.scss";

function LogoPug() {
  return (
    <div id="logo-pug">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.71 33.1">
        <path
          class="cls-1"
          d="M45.1,10.5c-.1-1.8.4-3.5-.4-4.9-2-3.5-7-5.5-17-5.5V0h0V.1C16.7.1,12,2.1,10,5.6c-.8,1.4-.8,3-.9,4.9-.1,1.5-.8,3.1-.5,4.7.3,2.2.5,4.4.8,6.4.3,1.7,2.7,3,3,4.6.6,4.3,4.4,6.3,15.4,6.3V33h-.2v-.5c10,0,14.2-2,14.8-6.3.2-1.6,2.2-2.9,2.5-4.6.3-2,.4-4.2.7-6.4C45.9,13.5,45.1,11.9,45.1,10.5Z"
        />
        <path
          class="cls-2"
          d="M27.2,12.1c1.8,0,3,1.1,4.2,2.2,1.4,1.2,3.7,2,4.7,3.1a9,9,0,0,1,2.7,3.8,17.08,17.08,0,0,1,.6,4.3c0,.5.3.4,1.1,0a17.3,17.3,0,0,0,4.2-3.6c-.3,1.7-2.4,3.2-2.6,4.8-.6,4.3-4.8,6.4-15.1,6.4h.6"
        />
        <path
          class="cls-2"
          d="M33.6,8.7c.4,1.3,1.7,5.8-.9,4.3,0,0,1.1.6,1.8,3A3.51,3.51,0,0,0,37,18.7a6.67,6.67,0,0,0,4.9-1.2,5.67,5.67,0,0,0,1.3-6.3c-.7-2-2.1-2.7-4.1-3.1S33.2,7.2,33.6,8.7Z"
        />
        <circle cx="38.7" cy="13.5" r="3.7" />
        <circle class="cls-3" cx="37.7" cy="11.8" r="1" />
        <path
          class="cls-1"
          d="M33,13.3a33.91,33.91,0,0,1,5.2,4.5C40,20.1,40,20.5,40,20.5l-1.3.6s-1.8-3.5-4.1-4.8S32,13.9,32,13.9Z"
        />
        <path
          class="cls-2"
          d="M27.3,12.1a6.92,6.92,0,0,0-4.5,2.2c-1.4,1.2-3.9,2-4.8,3.1a9.22,9.22,0,0,0-2.8,3.8,17.08,17.08,0,0,0-.6,4.3c0,.5.1.6-1.2,0a15.29,15.29,0,0,1-4.2-3.6c.3,1.7,2.4,3.2,2.6,4.8.6,4.2,4.8,6.3,15.1,6.3h.7"
        />
        <path
          class="cls-4"
          d="M36.9,19a12.57,12.57,0,0,0-9.8-5h0a12.73,12.73,0,0,0-9.8,5c-2.1,2.6-2,3.7-2,6.1,0,8.9,3.1,6.2,5.2,7.3s4.3.6,6.5.6h0c2.3,0,4.4.4,6.5-.6s5.2,1.6,5.2-7.3C38.9,22.6,39,21.5,36.9,19Z"
        />
        <path class="cls-5" d="M26.9,16.5s-.3,4.1-.9,4.9l1.1-.3Z" />
        <path class="cls-5" d="M27.2,16.5s.3,4.1.9,4.9L27,21.1Z" />
        <path
          class="cls-4"
          d="M23.7,15.5c-.4-1,1.1-2.6,3.5-2.6s3.9,1.6,3.5,2.6-2,1.3-3.5,1.3S24.1,16.5,23.7,15.5Z"
        />
        <path d="M27.2,16.5c0-.2,1.4-.2,2.2-.7,0,0-1.6.5-1.9.3a1.26,1.26,0,0,1-.4-.7,1.26,1.26,0,0,1-.4.7c-.3.2-2.1-.3-2.1-.3a15.46,15.46,0,0,1,2.3.7v.8a20.12,20.12,0,0,0,.2,3.9c.3.4.2-2.8.2-3.9A2.49,2.49,0,0,1,27.2,16.5Z" />
        <path
          class="cls-6"
          d="M27.2,22.2a6.43,6.43,0,0,1,3.1,1.3c-1.1-1.2-2.4-2.1-3.1-2.1a5.82,5.82,0,0,0-3.5,2.3A6.35,6.35,0,0,1,27.2,22.2Z"
        />
        <path
          class="cls-5"
          d="M27,29.6a17.07,17.07,0,0,1-4.6-.9c-.3-.1-.6.1-.9,0a11.84,11.84,0,0,1-6.1-4.1V25c0,8.9,3.1,6.2,5.2,7.3s4.3.6,6.5.6h0c2.3,0,4.4.4,6.5-.6s5.2,1.6,5.2-7.3v-.9a11.34,11.34,0,0,1-6.2,4.5c-.3.1-.8-.1-1.1-.1A13.51,13.51,0,0,1,27,29.6Z"
        />
        <path
          class="cls-5"
          d="M22.9,24.8s-1.1,3.2-.3,4.6l-1-.4A29.6,29.6,0,0,1,22.9,24.8Z"
        />
        <path
          class="cls-5"
          d="M31.3,24.8s1.1,3.2.3,4.6l1-.4A20.39,20.39,0,0,0,31.3,24.8Z"
        />
        <path
          class="cls-2"
          d="M20.3,8.7c-.4,1.3-1.7,5.8.9,4.3,0,0-1.1.6-1.8,3a3.51,3.51,0,0,1-2.5,2.7A6.67,6.67,0,0,1,12,17.5a5.67,5.67,0,0,1-1.3-6.3c.7-2,2.1-2.7,4.1-3.1S20.7,7.2,20.3,8.7Z"
        />
        <path d="M27.1,21.4c1.1,0,4.7,2.4,4.7,5.5v-.2c0-3.1-2.9-5.6-4.7-5.6s-4.7,2.5-4.7,5.6v.2C22.5,23.8,26,21.4,27.1,21.4Z" />
        <ellipse
          class="cls-7"
          cx="27.9"
          cy="14.9"
          rx="0.3"
          ry="0.6"
          transform="translate(-2.84 7.44) rotate(-14.46)"
        />
        <ellipse
          class="cls-7"
          cx="26.1"
          cy="14.91"
          rx="0.6"
          ry="0.3"
          transform="translate(4.13 35.43) rotate(-72.76)"
        />
        <circle cx="15.4" cy="13.5" r="3.7" />
        <circle class="cls-3" cx="16.4" cy="11.8" r="1" />
        <path
          class="cls-6"
          d="M28.2,13.5s-1,.4-1.2.5a4.78,4.78,0,0,1-1.2-.5h2.4Z"
        />
        <path
          class="cls-1"
          d="M20.9,13.3a33.91,33.91,0,0,0-5.2,4.5c-1.8,2.3-1.8,2.7-1.8,2.7l1.3.6s1.8-3.5,4.1-4.8,2.6-2.4,2.6-2.4Z"
        />
        <path
          class="cls-2"
          d="M13.6,6.6A8.67,8.67,0,0,1,9.9,11S13.8,10.5,13.6,6.6Z"
        />
        <path
          class="cls-2"
          d="M40.1,6.6A8.67,8.67,0,0,0,43.8,11S39.9,10.5,40.1,6.6Z"
        />
        <path
          class="cls-2"
          d="M33.2,12.4a9.9,9.9,0,0,0-5.9-2.7h.3a7.39,7.39,0,0,0-5.8,2.7,9.27,9.27,0,0,1,5.8-2h0A11.1,11.1,0,0,1,33.2,12.4Z"
        />
        <path
          class="cls-2"
          d="M30.2,6.9s-1.4-1.8-3-1.8h.3a3.43,3.43,0,0,0-2.9,1.8,4.19,4.19,0,0,1,2.9-1.3h0A3.63,3.63,0,0,1,30.2,6.9Z"
        />
        <path
          class="cls-2"
          d="M15.4,19.9s-2,1-3.4.4h-.4c-1-.7-1.7-2.9-1.7-2.9A4.35,4.35,0,0,0,15.4,19.9Z"
        />
        <path
          class="cls-2"
          d="M38.6,19.9s2,1,3.4.4h-.4a3.36,3.36,0,0,0,2.1-2.9A3.88,3.88,0,0,1,38.6,19.9Z"
        />
        <path
          class="cls-2"
          d="M17.7,6.4a9.11,9.11,0,0,1,4.3.2c1.8.6.4,3,.7,3.1s.9-1.6,4.3-1.4,3.8,1.7,4.3,1.4S30.8,5.5,36,6.4c0,0-5.3-1.4-5.1,2.4a7,7,0,0,0-7.4-.2S24.6,4.9,17.7,6.4Z"
        />
        <path class="cls-2" d="M36.4,6.4h0Z" />
        <path
          class="cls-2"
          d="M16.3,1.2s-4.6,5.7-5.2,7.9S9.9,20.5,9.3,21.8l-2.1-9,3.9-9.4Z"
        />
        <path
          class="cls-2"
          d="M37.7,1.2s4.6,5.7,5.2,7.9,1.2,11.4,1.8,12.7l2.1-9L42.9,3.4Z"
        />
        <path
          class="cls-4"
          d="M37.4,1.2s4.9,4.1,6.3,8.8.8,10.5,1.9,11.2c1.6,1,3.3-5,3.9-5.6.9-1,4-3.6,4.2-4.1S47.5,4,44.6,2.6C41.4,1,37.2.8,37.4,1.2Z"
        />
        <path
          class="cls-5"
          d="M41.6,3.7s5.8,7.4,5.7,16.6l2.3-4.7A31.77,31.77,0,0,0,41.6,3.7Z"
        />
        <path
          class="cls-4"
          d="M16.3,1.2S11.4,5.3,10,10,9.2,20.5,8.1,21.2c-1.6,1-3.3-5-3.9-5.6-.9-1-4-3.6-4.2-4.1S6.2,4,9.1,2.6C12.3,1,16.5.8,16.3,1.2Z"
        />
        <path
          class="cls-5"
          d="M12.1,3.7S6.3,11.1,6.4,20.3L4.1,15.6A31.77,31.77,0,0,1,12.1,3.7Z"
        />
        <path
          class="cls-2"
          d="M8.9,20.4s.4,2.3,1.1,3,.2-.6.2-.6-.7-.4-.6-3.3S8.9,20.4,8.9,20.4Z"
        />
        <path
          class="cls-2"
          d="M44.9,20.4s-.4,2.3-1.1,3-.2-.6-.2-.6.7-.4.6-3.3S44.9,20.4,44.9,20.4Z"
        />
        <circle class="cls-5" cx="22.8" cy="18.7" r="0.3" />
        <circle class="cls-5" cx="20.8" cy="20.8" r="0.3" />
        <circle class="cls-5" cx="20.2" cy="18.7" r="0.3" />
        <circle class="cls-5" cx="31" cy="18.7" r="0.3" />
        <circle class="cls-5" cx="32.9" cy="20.8" r="0.3" />
        <circle class="cls-5" cx="33.5" cy="18.7" r="0.3" />
      </svg>
    </div>
  );
}

export default LogoPug;
