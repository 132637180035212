import React from "react";
import "../App.scss";

function Students() {
  return (
    <div id="students">
      <img src="/assets/images/students.png" alt="Students" id="students" />
    </div>
  );
}

export default Students;
