import React from "react";
import { Link } from "react-router-dom";
import isExternal from "is-url-external";

function ButtonLink({
  buttonLink,
  buttonText,
  buttonWidth = "240px",
  buttonType,
  ...restOfProps
}) {
  return isExternal(buttonLink) ? (
    <a
      href={buttonLink}
      target="_blank"
      rel="noopener noreferrer"
      {...restOfProps}
    >
      {buttonType === "primary" ? (
        <div className="button-primary-container">
          <svg viewBox="0 0 134 39" height="100%" width={buttonWidth}>
            <path
              d="M80.56,37.71 C68.13,38.47 55.71,39.26 43.26,39.51 C36.73,39.64 30.19,39.62 23.66,39.36 C18.88,39.17 13.53,39.4 8.87,38.21 C0.55,36.09 0.52,23.96 1.34,17.11 C1.75,13.76 2.33,9.27 5.04,6.88 C7.1,5.06 11,5 12.79,5.15 C25.48,5.68 37.41,4.15 50,2.76 C64.13,1.2 78.37,0.8 92.57,1.08 C99.04,1.2 105.5,1.48 111.96,1.84 C116.27,2.09 122.45,1.37 126.47,3.13 C129.93,4.65 130.15,9.63 130.98,12.78 C132.22,17.49 134.27,22.57 134.65,27.43 C135.38,36.75 93.32,36.92 80.56,37.71 Z"
              id="Path"
            ></path>
          </svg>
          <span className="button-text">{buttonText}</span>
        </div>
      ) : (
        <div className="button-primary-container">
          <svg viewBox="0 0 142.58 47.86" height="100%" width={buttonWidth}>
            <path
              className="st0"
              d="M140.31,15.53c-1.6,1.06-3.17,2.15-4.73,3.26c-0.3-0.98-0.59-1.96-0.84-2.92c-0.07-0.25-0.13-0.51-0.19-0.78
                      c0.46-0.7,0.92-1.38,1.37-2.08c0.93-1.44-1.02-2.85-2.25-1.89c-0.58-2.1-1.51-4.04-3.45-4.89c-0.28-0.12-0.58-0.23-0.88-0.33
                      c0.14-0.2,0.28-0.41,0.42-0.61c0.94-1.4-1.25-3.12-2.36-1.82c-0.49,0.58-0.98,1.15-1.47,1.73c-3.42-0.38-7.26-0.09-10.22-0.26
                      c-1.61-0.09-3.22-0.16-4.83-0.23c0.37-0.79,0.73-1.58,1.1-2.36c0.56-1.21-0.71-3.02-2.05-2.05c-1.91,1.37-3.83,2.75-5.74,4.12
                      c-2.62-0.1-5.24-0.19-7.86-0.24c-3.2-0.06-6.41-0.09-9.62-0.07c-0.27-0.84-1.37-1.38-2.2-0.87c-0.48,0.29-0.94,0.6-1.38,0.91
                      c-8.56,0.11-17.1,0.53-25.62,1.35c-0.32-0.09-0.68-0.07-1.03,0.1c-0.89,0.09-1.78,0.17-2.67,0.27c0.06-0.06,0.12-0.12,0.18-0.19
                      c1.39-1.35-0.72-3.45-2.12-2.12c-1,0.94-1.98,1.9-2.97,2.85c-1.12,0.13-2.24,0.25-3.36,0.37c0.72-0.85,1.46-1.69,2.18-2.54
                      c1.23-1.45-0.73-3.51-2.12-2.12c-1.71,1.71-3.4,3.45-5.09,5.18c-2.91,0.29-5.8,0.53-8.71,0.71c0.07-0.09,0.15-0.18,0.23-0.27
                      c0.63-0.82,0.07-1.74-0.73-2.09c0.25-0.32,0.51-0.65,0.76-0.97c1.01-1.3-0.21-3.19-1.82-2.36c-3.58,1.85-7.06,3.87-10.45,6.01
                      c-1.09-0.02-2.17-0.03-3.27-0.07C13.9,8.26,10.89,8.12,8.8,9.97c-2.71,2.39-3.29,6.87-3.7,10.23c-0.03,0.22-0.05,0.44-0.08,0.67
                      c-0.16,0.15-0.32,0.31-0.48,0.46c-0.82,0.79-0.46,1.79,0.27,2.25c-0.01,0.21-0.02,0.43-0.03,0.64c-0.81,0.79-1.62,1.57-2.42,2.37
                      c-1.37,1.38,0.7,3.41,2.12,2.12c0.12-0.11,0.24-0.22,0.36-0.33c0.12,1.77,0.39,3.53,0.86,5.18c-1.78,2.28-3.57,4.57-5.35,6.85
                      c-1.16,1.48,0.69,3.5,2.12,2.12c1.23-1.19,2.47-2.39,3.7-3.58c0.43,0.12,0.92,0.02,1.31-0.46c0.11-0.14,0.24-0.27,0.35-0.41
                      c1.16,1.53,2.71,2.68,4.78,3.2c4.66,1.19,10.01,0.96,14.79,1.15c0.34,0.01,0.68,0.02,1.02,0.03c-0.53,0.68-1.08,1.35-1.61,2.03
                      c-1.15,1.48,0.82,3.61,2.12,2.12c1.17-1.34,2.35-2.69,3.52-4.03c0.64,0.01,1.27,0.02,1.91,0.03c0.31,0.95,1.69,1.57,2.54,0.69
                      c0.21-0.22,0.42-0.43,0.63-0.65c1.57,0.01,3.14,0.01,4.71,0c-0.66,0.7-1.33,1.39-2.01,2.07c-1.37,1.37,0.71,3.44,2.12,2.12
                      c1.45-1.35,2.8-2.77,4.07-4.24c0.19,0,0.37,0,0.56-0.01c0.19,0,0.37-0.01,0.56-0.02c0.51,0.58,1.4,0.81,2.12,0.09
                      c0.05-0.05,0.1-0.1,0.16-0.16c5.47-0.14,10.95-0.37,16.41-0.66c-1.06,1.17-2.11,2.35-3.18,3.51c-1.34,1.45,0.64,3.3,2.12,2.12
                      c2.46-1.96,4.88-3.96,7.28-5.98c3.94-0.23,7.89-0.47,11.83-0.71c0.6-0.04,1.2-0.07,1.81-0.11c-0.15,0.18-0.31,0.35-0.46,0.53
                      c-1.22,1.46,0.7,3.47,2.12,2.12c1.03-0.98,2.07-1.96,3.1-2.94c7.8-0.46,15.6-0.87,23.4-1.09c0.37,0.89,1.65,1.42,2.48,0.57
                      c0.21-0.22,0.41-0.43,0.62-0.65c1.75-0.04,3.49-0.07,5.24-0.08c0.62,0,1.27,0,1.94,0c0.36,0.18,0.78,0.21,1.18,0
                      c0.23,0,0.47,0,0.7-0.01c-0.21,0.31-0.41,0.63-0.61,0.93c-0.97,1.43,1.17,3,2.36,1.82c1.07-1.08,2.18-2.12,3.28-3.16
                      c4.1-0.73,7.37-2.74,6.94-8.21c-0.09-1.21-0.29-2.44-0.56-3.67c0.08-0.14,0.17-0.28,0.26-0.42c0.47-0.78,0.15-1.54-0.45-1.94
                      c1.62-2.26,3.2-4.56,4.7-6.91C143.16,16.34,141.56,14.7,140.31,15.53z M40.5,39.66c0.42-0.42,0.84-0.83,1.26-1.25
                      c-0.29,0.4-0.56,0.82-0.83,1.25C40.79,39.66,40.64,39.66,40.5,39.66z M16.51,39.08c0.75-0.79,1.49-1.59,2.23-2.38
                      c-0.08,0.11-0.16,0.21-0.24,0.32c-0.84,1.18,0.35,2.24,1.49,2.15c0.44,0.12,0.93,0.02,1.34-0.43c0.42-0.45,0.85-0.9,1.26-1.35
                      c0.67-0.55,1.33-1.1,1.99-1.65c0.02,0.11,0.05,0.23,0.1,0.33c-0.9,1.09-1.77,2.22-2.6,3.39C20.18,39.44,18.29,39.34,16.51,39.08z
                      M115.35,10.21c0.09,0.5,0.13,0.99,0.11,1.48c-0.55-0.52-1.48-0.69-2.13-0.05c-0.31,0.3-0.62,0.59-0.93,0.89
                      c0.22-0.61-0.02-1.2-0.46-1.57c0.09-0.13,0.19-0.26,0.28-0.39C113.26,10.55,114.29,10.44,115.35,10.21z M79.89,15.88
                      c-1.18,0.78-2.36,1.57-3.54,2.35c1.44-1.82,2.9-3.62,4.39-5.4c0.07-0.03,0.14-0.07,0.21-0.1C80.59,13.78,80.24,14.83,79.89,15.88z
                      M72.04,24.69c-0.74,0.72-1.49,1.44-2.23,2.16c0.32-0.44,0.65-0.88,0.97-1.32C71.2,25.25,71.62,24.97,72.04,24.69z M51.55,25.11
                      c-0.56,0.5-1.12,1-1.67,1.5c0.02-0.05,0.03-0.09,0.05-0.14c0.87-0.91,1.75-1.82,2.64-2.72C52.23,24.2,51.89,24.65,51.55,25.11z
                      M53.29,15.83c-0.67,0.62-1.35,1.24-2.02,1.86c-0.01-0.03-0.02-0.06-0.03-0.08c0.05-0.06,0.11-0.12,0.16-0.18
                      c0.17-0.2,0.28-0.4,0.35-0.6c0.61-0.46,1.23-0.92,1.84-1.38C53.48,15.57,53.39,15.7,53.29,15.83z M29.86,31.64
                      c-0.02,0.03-0.03,0.07-0.05,0.1c-0.11-0.04-0.23-0.07-0.35-0.09c0.27-0.23,0.54-0.47,0.82-0.7C30.14,31.19,29.99,31.41,29.86,31.64z
                      M89.13,14.97c-0.05,0.02-0.11,0.03-0.16,0.06c-0.3,0.18-0.59,0.36-0.88,0.55c1.12-1.35,2.25-2.7,3.38-4.04
                      c0.59-0.21,1.18-0.41,1.77-0.62C91.87,12.27,90.5,13.62,89.13,14.97z M70.05,15.88c-0.29-0.04-0.6,0.03-0.92,0.28
                      c-0.1,0.08-0.2,0.16-0.3,0.24c0,0,0,0,0,0c0,0,0,0-0.01,0.01c-0.31,0.23-0.61,0.47-0.91,0.71c2.23-2.21,4.44-4.45,6.62-6.72
                      c0.3,0.08,0.59,0.16,0.89,0.24C73.64,12.38,71.85,14.13,70.05,15.88z M68.74,11.85c-0.4-0.52-1.07-0.77-1.81-0.28
                      c-1.15,0.76-2.27,1.56-3.37,2.37c0.58-0.78,1.16-1.56,1.74-2.34c1.69-0.65,3.4-1.25,5.21-1.52C69.91,10.65,69.32,11.25,68.74,11.85z
                      M16.86,19.28c-0.04,0.02-0.08,0.05-0.11,0.07c0.85-1.08,1.7-2.16,2.58-3.23C18.51,17.18,17.69,18.23,16.86,19.28z M30.87,17.49
                      c-0.01-0.01-0.02-0.01-0.03-0.02c0.39-0.42,0.79-0.84,1.18-1.25C31.63,16.64,31.25,17.07,30.87,17.49z M85.52,31.61
                      c0.29-0.44,0.59-0.88,0.91-1.31c0.18,0,0.37-0.04,0.55-0.12c-0.25,0.32-0.5,0.64-0.74,0.96C85.99,31.3,85.75,31.45,85.52,31.61z
                      M131.02,28.36c-0.03-0.05-0.05-0.1-0.08-0.15c0.08-0.19,0.17-0.39,0.27-0.6c0.54-0.62,1.08-1.24,1.61-1.87
                      c0.37-0.43,0.47-0.88,0.39-1.29c0.14-0.19,0.27-0.38,0.41-0.56c0.03-0.03,0.07-0.05,0.1-0.08c0.06,0.24,0.13,0.48,0.19,0.72
                      C132.98,25.83,132.01,27.11,131.02,28.36z M122.04,27.78c-0.39-0.03-0.79,0.07-1.13,0.34c-0.06,0.05-0.12,0.1-0.18,0.15
                      c1.43-1.56,2.87-3.1,4.33-4.62C124.06,25.04,123.06,26.41,122.04,27.78z M104.15,15.74c-0.19,0.16-0.38,0.31-0.57,0.47
                      c0.76-1,1.52-2,2.28-3c0.47-0.61,0.23-1.32-0.26-1.79c0.13-0.3,0.16-0.61,0.11-0.9c0.09-0.11,0.17-0.22,0.26-0.33
                      c0.26-0.32,0.37-0.66,0.38-0.99c0.18,0.26,0.45,0.47,0.81,0.58c0.45,0.14,0.89,0.26,1.33,0.36C107.03,12,105.58,13.86,104.15,15.74z
                      M106.42,7.24c-0.05,0.11-0.11,0.23-0.16,0.34c-0.11,0.24-0.17,0.49-0.17,0.74c-0.28-0.43-0.76-0.71-1.28-0.68
                      c0.19-0.13,0.38-0.27,0.56-0.4C105.72,7.24,106.07,7.24,106.42,7.24z M92.8,7.9c-0.16-0.33-0.44-0.61-0.77-0.79
                      c0.98,0.01,1.95,0.02,2.93,0.03C94.23,7.4,93.52,7.65,92.8,7.9z M79.46,7.33c-0.33-0.03-0.68,0.07-1.01,0.34
                      c-1.02,0.01-2-0.17-2.99-0.37c1.37-0.05,2.74-0.07,4.12-0.11C79.54,7.24,79.5,7.28,79.46,7.33z M66.01,8.25
                      c0.07-0.07,0.13-0.14,0.2-0.21c0.09-0.09,0.16-0.18,0.22-0.28c0.4-0.03,0.8-0.06,1.2-0.08C67.09,7.85,66.55,8.04,66.01,8.25z
                      M30.45,13.41c0-0.01,0-0.02,0-0.02c0.31-0.35,0.61-0.7,0.92-1.04c0.31-0.34,0.43-0.73,0.41-1.09c0.27-0.01,0.55-0.03,0.82-0.05
                      C31.88,11.94,31.16,12.67,30.45,13.41z M58.81,37.71c-0.21,0.57,0,1.11,0.4,1.48c-0.6,0.03-1.19,0.05-1.79,0.07
                      C57.89,38.75,58.35,38.23,58.81,37.71z M63.85,36.77c0,0.04-0.01,0.08-0.01,0.12c-0.05,0.73,0.48,1.26,1.12,1.43
                      c0.01,0.21,0.09,0.41,0.2,0.58c-1.24,0.06-2.47,0.13-3.71,0.19C62.26,38.32,63.05,37.55,63.85,36.77z M88.27,33.39
                      c0.39-0.26,0.79-0.52,1.18-0.77c-0.59,0.53-1.2,1.09-1.83,1.63C87.84,33.96,88.06,33.67,88.27,33.39z M98.33,36.68
                      c0.64,0.3,1.47,0.24,1.98-0.5c0.52-0.75,1.06-1.49,1.59-2.23c-0.15,1.11,0.97,2.18,1.96,1.73c-0.13,0.19-0.25,0.37-0.38,0.56
                      c-0.11,0.16-0.18,0.33-0.21,0.49c-1.75,0.08-3.5,0.17-5.25,0.27C98.12,36.89,98.22,36.79,98.33,36.68z M107.77,36.04
                      c0.1,0.18,0.23,0.35,0.39,0.48c-0.3,0.01-0.59,0.02-0.89,0.04C107.44,36.39,107.6,36.21,107.77,36.04z M110.12,36.44
                      c0.89-0.94,1.78-1.88,2.67-2.81c0,0,0,0.01,0,0.01c-0.56,0.84-1.12,1.67-1.67,2.51c-0.06,0.09-0.1,0.17-0.14,0.26
                      C110.7,36.42,110.41,36.43,110.12,36.44C110.12,36.44,110.12,36.44,110.12,36.44z M115.56,36.28
                      C115.57,36.28,115.57,36.28,115.56,36.28C115.57,36.28,115.57,36.28,115.56,36.28C115.57,36.28,115.56,36.28,115.56,36.28z
                      M132.73,19.99c-1.15,0.84-2.04,1.76-2.71,2.87c-0.53,0.4-1.05,0.81-1.58,1.21c1.32-1.85,2.62-3.72,3.89-5.6
                      C132.46,18.97,132.59,19.48,132.73,19.99z M131.03,13.58c-0.28,0.26-0.55,0.52-0.83,0.78c0.09-0.5,0.19-1.01,0.28-1.51
                      c0.28-1.48-1.34-2.47-2.51-1.46c-2.1,1.83-4.15,3.72-6.17,5.64c1.81-2.67,3.61-5.34,5.42-8.01C129.27,9.72,130.31,11,131.03,13.58z
                      M123.35,8.24c-0.2,0.24-0.4,0.48-0.61,0.72c-0.41-0.08-0.86,0.03-1.26,0.44c-0.75,0.78-1.52,1.55-2.27,2.32
                      c-0.07-0.54-0.43-1.02-0.89-1.26c-0.07-0.79-0.22-1.6-0.47-2.45c-0.04-0.14-0.11-0.27-0.18-0.39
                      C119.58,7.76,121.48,7.97,123.35,8.24z M110.74,7.3c-0.04,0.05-0.09,0.11-0.13,0.16c-0.31-0.04-0.62-0.08-0.94-0.14
                      c0.01-0.01,0.01-0.02,0.02-0.04C110.04,7.29,110.39,7.29,110.74,7.3z M99.25,7.95c0.05-0.26,0.01-0.52-0.09-0.75
                      c0.38,0,0.75,0.01,1.13,0.01C99.94,7.46,99.59,7.7,99.25,7.95z M51.32,9.19c-1.13,0.96-2.2,1.97-3.21,3.04
                      c-0.09-0.05-0.19-0.1-0.29-0.13c0.85-0.95,1.72-1.88,2.59-2.8C50.72,9.26,51.02,9.22,51.32,9.19z M45.22,9.93
                      c-0.07,0.07-0.14,0.14-0.21,0.21c-0.42-0.08-0.89,0-1.31,0.33c-1.43,1.15-2.87,2.3-4.3,3.44c1.05-1.21,2.09-2.43,3.14-3.65
                      C43.43,10.15,44.33,10.04,45.22,9.93z M35.29,12.75c0.51-0.56,0.48-1.22,0.18-1.73c0.53-0.04,1.05-0.09,1.58-0.13
                      C36.46,11.5,35.88,12.13,35.29,12.75z M9.64,14.1c1.36-2.45,3.28-2.78,5.68-2.85c-0.88,0.6-1.75,1.21-2.62,1.83
                      c-0.18,0.02-0.35,0.06-0.53,0.15c-0.06,0.03-0.12,0.07-0.18,0.1c-0.27,0.05-0.53,0.18-0.78,0.42c-0.11,0.1-0.21,0.21-0.32,0.31
                      c-0.23,0.22-0.36,0.47-0.41,0.73c-0.12,0.08-0.25,0.18-0.42,0.3c-0.58,0.41-0.74,1.02-0.63,1.58c-0.33,0.32-0.66,0.63-0.99,0.95
                      C8.7,16.34,9.07,15.13,9.64,14.1z M7.9,25.63C7.86,25.82,7.88,26,7.92,26.18c-0.04,0.06-0.08,0.12-0.12,0.17
                      c0-0.21-0.01-0.42-0.01-0.62C7.83,25.7,7.86,25.66,7.9,25.63z M8.1,30.13c0.05,0.06,0.1,0.11,0.16,0.17
                      c-0.04,0.05-0.08,0.1-0.12,0.15C8.13,30.35,8.12,30.24,8.1,30.13z M9.95,35.62c0.47-0.56,0.94-1.11,1.42-1.67
                      c0.21-0.2,0.41-0.4,0.62-0.6c-0.04,0.55,0.29,1.03,0.77,1.29c0.31,0.25,0.7,0.39,1.09,0.35c-0.03,0.51,0.26,0.93,0.69,1.19
                      c-0.47,0.7-0.96,1.38-1.42,2.09C11.75,37.74,10.72,36.81,9.95,35.62z M26.72,39.48c0.21-0.18,0.42-0.35,0.63-0.53
                      c0.01,0.2,0.06,0.38,0.15,0.53C27.25,39.48,26.98,39.48,26.72,39.48z M30.15,39.53c0.31-0.28,0.61-0.57,0.92-0.85
                      c0.08,0.06,0.16,0.11,0.25,0.16c-0.19,0.24-0.37,0.48-0.56,0.71C30.55,39.54,30.35,39.54,30.15,39.53z M40.47,33.44
                      c-1.55,2.05-3.07,4.11-4.56,6.2c-0.28,0-0.56-0.01-0.84-0.01C36.86,37.57,38.66,35.51,40.47,33.44z M54.17,38.21
                      c-0.1,0.19-0.2,0.37-0.3,0.56c-0.11,0.21-0.16,0.43-0.17,0.64c-0.25,0.01-0.5,0.02-0.76,0.03C53.35,39.03,53.76,38.62,54.17,38.21z
                      M68.66,38.72c0.47-0.44,0.93-0.88,1.4-1.32c0.05,0.02,0.11,0.03,0.16,0.04c-0.37,0.42-0.73,0.84-1.1,1.25
                      C68.96,38.71,68.81,38.71,68.66,38.72z M88.7,37.52c0.14-0.1,0.27-0.2,0.4-0.31c-0.09,0.1-0.17,0.2-0.26,0.3
                      C88.8,37.52,88.75,37.52,88.7,37.52z M94.45,37.01c0.01,0.06,0.02,0.12,0.03,0.17c-0.08,0-0.15,0.01-0.23,0.01
                      C94.32,37.14,94.39,37.08,94.45,37.01z M120.29,36.22c0.42-0.43,0.83-0.86,1.25-1.3c0.14,0.15,0.32,0.28,0.51,0.36
                      c0.46,0.78,1.51,1.2,2.27,0.32c1.01-1.17,2.02-2.34,3.03-3.51c0.27,0.03,0.55,0,0.78-0.11c-1.25,1.5-2.53,2.97-3.83,4.42
                      C122.92,36.35,121.56,36.26,120.29,36.22z M135.2,31.49c-0.1,0.04-0.19,0.08-0.28,0.15c-0.02,0.02-0.05,0.03-0.07,0.05
                      c0.14-0.22,0.27-0.44,0.41-0.66C135.24,31.18,135.23,31.34,135.2,31.49z"
            />
          </svg>
          <span className="button-text">{buttonText}</span>
        </div>
      )}
    </a>
  ) : (
    <Link to={buttonLink} {...restOfProps}>
      {buttonType === "primary" ? (
        <div className="button-primary-container">
          <svg viewBox="0 0 134 39" height="100%" width={buttonWidth}>
            <path
              d="M80.56,37.71 C68.13,38.47 55.71,39.26 43.26,39.51 C36.73,39.64 30.19,39.62 23.66,39.36 C18.88,39.17 13.53,39.4 8.87,38.21 C0.55,36.09 0.52,23.96 1.34,17.11 C1.75,13.76 2.33,9.27 5.04,6.88 C7.1,5.06 11,5 12.79,5.15 C25.48,5.68 37.41,4.15 50,2.76 C64.13,1.2 78.37,0.8 92.57,1.08 C99.04,1.2 105.5,1.48 111.96,1.84 C116.27,2.09 122.45,1.37 126.47,3.13 C129.93,4.65 130.15,9.63 130.98,12.78 C132.22,17.49 134.27,22.57 134.65,27.43 C135.38,36.75 93.32,36.92 80.56,37.71 Z"
              id="Path"
            ></path>
          </svg>
          <span className="button-text">{buttonText}</span>
        </div>
      ) : (
        <div className="button-primary-container">
          <svg viewBox="0 0 142.58 47.86" height="100%" width={buttonWidth}>
            <path
              className="st0"
              d="M140.31,15.53c-1.6,1.06-3.17,2.15-4.73,3.26c-0.3-0.98-0.59-1.96-0.84-2.92c-0.07-0.25-0.13-0.51-0.19-0.78
                      c0.46-0.7,0.92-1.38,1.37-2.08c0.93-1.44-1.02-2.85-2.25-1.89c-0.58-2.1-1.51-4.04-3.45-4.89c-0.28-0.12-0.58-0.23-0.88-0.33
                      c0.14-0.2,0.28-0.41,0.42-0.61c0.94-1.4-1.25-3.12-2.36-1.82c-0.49,0.58-0.98,1.15-1.47,1.73c-3.42-0.38-7.26-0.09-10.22-0.26
                      c-1.61-0.09-3.22-0.16-4.83-0.23c0.37-0.79,0.73-1.58,1.1-2.36c0.56-1.21-0.71-3.02-2.05-2.05c-1.91,1.37-3.83,2.75-5.74,4.12
                      c-2.62-0.1-5.24-0.19-7.86-0.24c-3.2-0.06-6.41-0.09-9.62-0.07c-0.27-0.84-1.37-1.38-2.2-0.87c-0.48,0.29-0.94,0.6-1.38,0.91
                      c-8.56,0.11-17.1,0.53-25.62,1.35c-0.32-0.09-0.68-0.07-1.03,0.1c-0.89,0.09-1.78,0.17-2.67,0.27c0.06-0.06,0.12-0.12,0.18-0.19
                      c1.39-1.35-0.72-3.45-2.12-2.12c-1,0.94-1.98,1.9-2.97,2.85c-1.12,0.13-2.24,0.25-3.36,0.37c0.72-0.85,1.46-1.69,2.18-2.54
                      c1.23-1.45-0.73-3.51-2.12-2.12c-1.71,1.71-3.4,3.45-5.09,5.18c-2.91,0.29-5.8,0.53-8.71,0.71c0.07-0.09,0.15-0.18,0.23-0.27
                      c0.63-0.82,0.07-1.74-0.73-2.09c0.25-0.32,0.51-0.65,0.76-0.97c1.01-1.3-0.21-3.19-1.82-2.36c-3.58,1.85-7.06,3.87-10.45,6.01
                      c-1.09-0.02-2.17-0.03-3.27-0.07C13.9,8.26,10.89,8.12,8.8,9.97c-2.71,2.39-3.29,6.87-3.7,10.23c-0.03,0.22-0.05,0.44-0.08,0.67
                      c-0.16,0.15-0.32,0.31-0.48,0.46c-0.82,0.79-0.46,1.79,0.27,2.25c-0.01,0.21-0.02,0.43-0.03,0.64c-0.81,0.79-1.62,1.57-2.42,2.37
                      c-1.37,1.38,0.7,3.41,2.12,2.12c0.12-0.11,0.24-0.22,0.36-0.33c0.12,1.77,0.39,3.53,0.86,5.18c-1.78,2.28-3.57,4.57-5.35,6.85
                      c-1.16,1.48,0.69,3.5,2.12,2.12c1.23-1.19,2.47-2.39,3.7-3.58c0.43,0.12,0.92,0.02,1.31-0.46c0.11-0.14,0.24-0.27,0.35-0.41
                      c1.16,1.53,2.71,2.68,4.78,3.2c4.66,1.19,10.01,0.96,14.79,1.15c0.34,0.01,0.68,0.02,1.02,0.03c-0.53,0.68-1.08,1.35-1.61,2.03
                      c-1.15,1.48,0.82,3.61,2.12,2.12c1.17-1.34,2.35-2.69,3.52-4.03c0.64,0.01,1.27,0.02,1.91,0.03c0.31,0.95,1.69,1.57,2.54,0.69
                      c0.21-0.22,0.42-0.43,0.63-0.65c1.57,0.01,3.14,0.01,4.71,0c-0.66,0.7-1.33,1.39-2.01,2.07c-1.37,1.37,0.71,3.44,2.12,2.12
                      c1.45-1.35,2.8-2.77,4.07-4.24c0.19,0,0.37,0,0.56-0.01c0.19,0,0.37-0.01,0.56-0.02c0.51,0.58,1.4,0.81,2.12,0.09
                      c0.05-0.05,0.1-0.1,0.16-0.16c5.47-0.14,10.95-0.37,16.41-0.66c-1.06,1.17-2.11,2.35-3.18,3.51c-1.34,1.45,0.64,3.3,2.12,2.12
                      c2.46-1.96,4.88-3.96,7.28-5.98c3.94-0.23,7.89-0.47,11.83-0.71c0.6-0.04,1.2-0.07,1.81-0.11c-0.15,0.18-0.31,0.35-0.46,0.53
                      c-1.22,1.46,0.7,3.47,2.12,2.12c1.03-0.98,2.07-1.96,3.1-2.94c7.8-0.46,15.6-0.87,23.4-1.09c0.37,0.89,1.65,1.42,2.48,0.57
                      c0.21-0.22,0.41-0.43,0.62-0.65c1.75-0.04,3.49-0.07,5.24-0.08c0.62,0,1.27,0,1.94,0c0.36,0.18,0.78,0.21,1.18,0
                      c0.23,0,0.47,0,0.7-0.01c-0.21,0.31-0.41,0.63-0.61,0.93c-0.97,1.43,1.17,3,2.36,1.82c1.07-1.08,2.18-2.12,3.28-3.16
                      c4.1-0.73,7.37-2.74,6.94-8.21c-0.09-1.21-0.29-2.44-0.56-3.67c0.08-0.14,0.17-0.28,0.26-0.42c0.47-0.78,0.15-1.54-0.45-1.94
                      c1.62-2.26,3.2-4.56,4.7-6.91C143.16,16.34,141.56,14.7,140.31,15.53z M40.5,39.66c0.42-0.42,0.84-0.83,1.26-1.25
                      c-0.29,0.4-0.56,0.82-0.83,1.25C40.79,39.66,40.64,39.66,40.5,39.66z M16.51,39.08c0.75-0.79,1.49-1.59,2.23-2.38
                      c-0.08,0.11-0.16,0.21-0.24,0.32c-0.84,1.18,0.35,2.24,1.49,2.15c0.44,0.12,0.93,0.02,1.34-0.43c0.42-0.45,0.85-0.9,1.26-1.35
                      c0.67-0.55,1.33-1.1,1.99-1.65c0.02,0.11,0.05,0.23,0.1,0.33c-0.9,1.09-1.77,2.22-2.6,3.39C20.18,39.44,18.29,39.34,16.51,39.08z
                      M115.35,10.21c0.09,0.5,0.13,0.99,0.11,1.48c-0.55-0.52-1.48-0.69-2.13-0.05c-0.31,0.3-0.62,0.59-0.93,0.89
                      c0.22-0.61-0.02-1.2-0.46-1.57c0.09-0.13,0.19-0.26,0.28-0.39C113.26,10.55,114.29,10.44,115.35,10.21z M79.89,15.88
                      c-1.18,0.78-2.36,1.57-3.54,2.35c1.44-1.82,2.9-3.62,4.39-5.4c0.07-0.03,0.14-0.07,0.21-0.1C80.59,13.78,80.24,14.83,79.89,15.88z
                      M72.04,24.69c-0.74,0.72-1.49,1.44-2.23,2.16c0.32-0.44,0.65-0.88,0.97-1.32C71.2,25.25,71.62,24.97,72.04,24.69z M51.55,25.11
                      c-0.56,0.5-1.12,1-1.67,1.5c0.02-0.05,0.03-0.09,0.05-0.14c0.87-0.91,1.75-1.82,2.64-2.72C52.23,24.2,51.89,24.65,51.55,25.11z
                      M53.29,15.83c-0.67,0.62-1.35,1.24-2.02,1.86c-0.01-0.03-0.02-0.06-0.03-0.08c0.05-0.06,0.11-0.12,0.16-0.18
                      c0.17-0.2,0.28-0.4,0.35-0.6c0.61-0.46,1.23-0.92,1.84-1.38C53.48,15.57,53.39,15.7,53.29,15.83z M29.86,31.64
                      c-0.02,0.03-0.03,0.07-0.05,0.1c-0.11-0.04-0.23-0.07-0.35-0.09c0.27-0.23,0.54-0.47,0.82-0.7C30.14,31.19,29.99,31.41,29.86,31.64z
                      M89.13,14.97c-0.05,0.02-0.11,0.03-0.16,0.06c-0.3,0.18-0.59,0.36-0.88,0.55c1.12-1.35,2.25-2.7,3.38-4.04
                      c0.59-0.21,1.18-0.41,1.77-0.62C91.87,12.27,90.5,13.62,89.13,14.97z M70.05,15.88c-0.29-0.04-0.6,0.03-0.92,0.28
                      c-0.1,0.08-0.2,0.16-0.3,0.24c0,0,0,0,0,0c0,0,0,0-0.01,0.01c-0.31,0.23-0.61,0.47-0.91,0.71c2.23-2.21,4.44-4.45,6.62-6.72
                      c0.3,0.08,0.59,0.16,0.89,0.24C73.64,12.38,71.85,14.13,70.05,15.88z M68.74,11.85c-0.4-0.52-1.07-0.77-1.81-0.28
                      c-1.15,0.76-2.27,1.56-3.37,2.37c0.58-0.78,1.16-1.56,1.74-2.34c1.69-0.65,3.4-1.25,5.21-1.52C69.91,10.65,69.32,11.25,68.74,11.85z
                      M16.86,19.28c-0.04,0.02-0.08,0.05-0.11,0.07c0.85-1.08,1.7-2.16,2.58-3.23C18.51,17.18,17.69,18.23,16.86,19.28z M30.87,17.49
                      c-0.01-0.01-0.02-0.01-0.03-0.02c0.39-0.42,0.79-0.84,1.18-1.25C31.63,16.64,31.25,17.07,30.87,17.49z M85.52,31.61
                      c0.29-0.44,0.59-0.88,0.91-1.31c0.18,0,0.37-0.04,0.55-0.12c-0.25,0.32-0.5,0.64-0.74,0.96C85.99,31.3,85.75,31.45,85.52,31.61z
                      M131.02,28.36c-0.03-0.05-0.05-0.1-0.08-0.15c0.08-0.19,0.17-0.39,0.27-0.6c0.54-0.62,1.08-1.24,1.61-1.87
                      c0.37-0.43,0.47-0.88,0.39-1.29c0.14-0.19,0.27-0.38,0.41-0.56c0.03-0.03,0.07-0.05,0.1-0.08c0.06,0.24,0.13,0.48,0.19,0.72
                      C132.98,25.83,132.01,27.11,131.02,28.36z M122.04,27.78c-0.39-0.03-0.79,0.07-1.13,0.34c-0.06,0.05-0.12,0.1-0.18,0.15
                      c1.43-1.56,2.87-3.1,4.33-4.62C124.06,25.04,123.06,26.41,122.04,27.78z M104.15,15.74c-0.19,0.16-0.38,0.31-0.57,0.47
                      c0.76-1,1.52-2,2.28-3c0.47-0.61,0.23-1.32-0.26-1.79c0.13-0.3,0.16-0.61,0.11-0.9c0.09-0.11,0.17-0.22,0.26-0.33
                      c0.26-0.32,0.37-0.66,0.38-0.99c0.18,0.26,0.45,0.47,0.81,0.58c0.45,0.14,0.89,0.26,1.33,0.36C107.03,12,105.58,13.86,104.15,15.74z
                      M106.42,7.24c-0.05,0.11-0.11,0.23-0.16,0.34c-0.11,0.24-0.17,0.49-0.17,0.74c-0.28-0.43-0.76-0.71-1.28-0.68
                      c0.19-0.13,0.38-0.27,0.56-0.4C105.72,7.24,106.07,7.24,106.42,7.24z M92.8,7.9c-0.16-0.33-0.44-0.61-0.77-0.79
                      c0.98,0.01,1.95,0.02,2.93,0.03C94.23,7.4,93.52,7.65,92.8,7.9z M79.46,7.33c-0.33-0.03-0.68,0.07-1.01,0.34
                      c-1.02,0.01-2-0.17-2.99-0.37c1.37-0.05,2.74-0.07,4.12-0.11C79.54,7.24,79.5,7.28,79.46,7.33z M66.01,8.25
                      c0.07-0.07,0.13-0.14,0.2-0.21c0.09-0.09,0.16-0.18,0.22-0.28c0.4-0.03,0.8-0.06,1.2-0.08C67.09,7.85,66.55,8.04,66.01,8.25z
                      M30.45,13.41c0-0.01,0-0.02,0-0.02c0.31-0.35,0.61-0.7,0.92-1.04c0.31-0.34,0.43-0.73,0.41-1.09c0.27-0.01,0.55-0.03,0.82-0.05
                      C31.88,11.94,31.16,12.67,30.45,13.41z M58.81,37.71c-0.21,0.57,0,1.11,0.4,1.48c-0.6,0.03-1.19,0.05-1.79,0.07
                      C57.89,38.75,58.35,38.23,58.81,37.71z M63.85,36.77c0,0.04-0.01,0.08-0.01,0.12c-0.05,0.73,0.48,1.26,1.12,1.43
                      c0.01,0.21,0.09,0.41,0.2,0.58c-1.24,0.06-2.47,0.13-3.71,0.19C62.26,38.32,63.05,37.55,63.85,36.77z M88.27,33.39
                      c0.39-0.26,0.79-0.52,1.18-0.77c-0.59,0.53-1.2,1.09-1.83,1.63C87.84,33.96,88.06,33.67,88.27,33.39z M98.33,36.68
                      c0.64,0.3,1.47,0.24,1.98-0.5c0.52-0.75,1.06-1.49,1.59-2.23c-0.15,1.11,0.97,2.18,1.96,1.73c-0.13,0.19-0.25,0.37-0.38,0.56
                      c-0.11,0.16-0.18,0.33-0.21,0.49c-1.75,0.08-3.5,0.17-5.25,0.27C98.12,36.89,98.22,36.79,98.33,36.68z M107.77,36.04
                      c0.1,0.18,0.23,0.35,0.39,0.48c-0.3,0.01-0.59,0.02-0.89,0.04C107.44,36.39,107.6,36.21,107.77,36.04z M110.12,36.44
                      c0.89-0.94,1.78-1.88,2.67-2.81c0,0,0,0.01,0,0.01c-0.56,0.84-1.12,1.67-1.67,2.51c-0.06,0.09-0.1,0.17-0.14,0.26
                      C110.7,36.42,110.41,36.43,110.12,36.44C110.12,36.44,110.12,36.44,110.12,36.44z M115.56,36.28
                      C115.57,36.28,115.57,36.28,115.56,36.28C115.57,36.28,115.57,36.28,115.56,36.28C115.57,36.28,115.56,36.28,115.56,36.28z
                      M132.73,19.99c-1.15,0.84-2.04,1.76-2.71,2.87c-0.53,0.4-1.05,0.81-1.58,1.21c1.32-1.85,2.62-3.72,3.89-5.6
                      C132.46,18.97,132.59,19.48,132.73,19.99z M131.03,13.58c-0.28,0.26-0.55,0.52-0.83,0.78c0.09-0.5,0.19-1.01,0.28-1.51
                      c0.28-1.48-1.34-2.47-2.51-1.46c-2.1,1.83-4.15,3.72-6.17,5.64c1.81-2.67,3.61-5.34,5.42-8.01C129.27,9.72,130.31,11,131.03,13.58z
                      M123.35,8.24c-0.2,0.24-0.4,0.48-0.61,0.72c-0.41-0.08-0.86,0.03-1.26,0.44c-0.75,0.78-1.52,1.55-2.27,2.32
                      c-0.07-0.54-0.43-1.02-0.89-1.26c-0.07-0.79-0.22-1.6-0.47-2.45c-0.04-0.14-0.11-0.27-0.18-0.39
                      C119.58,7.76,121.48,7.97,123.35,8.24z M110.74,7.3c-0.04,0.05-0.09,0.11-0.13,0.16c-0.31-0.04-0.62-0.08-0.94-0.14
                      c0.01-0.01,0.01-0.02,0.02-0.04C110.04,7.29,110.39,7.29,110.74,7.3z M99.25,7.95c0.05-0.26,0.01-0.52-0.09-0.75
                      c0.38,0,0.75,0.01,1.13,0.01C99.94,7.46,99.59,7.7,99.25,7.95z M51.32,9.19c-1.13,0.96-2.2,1.97-3.21,3.04
                      c-0.09-0.05-0.19-0.1-0.29-0.13c0.85-0.95,1.72-1.88,2.59-2.8C50.72,9.26,51.02,9.22,51.32,9.19z M45.22,9.93
                      c-0.07,0.07-0.14,0.14-0.21,0.21c-0.42-0.08-0.89,0-1.31,0.33c-1.43,1.15-2.87,2.3-4.3,3.44c1.05-1.21,2.09-2.43,3.14-3.65
                      C43.43,10.15,44.33,10.04,45.22,9.93z M35.29,12.75c0.51-0.56,0.48-1.22,0.18-1.73c0.53-0.04,1.05-0.09,1.58-0.13
                      C36.46,11.5,35.88,12.13,35.29,12.75z M9.64,14.1c1.36-2.45,3.28-2.78,5.68-2.85c-0.88,0.6-1.75,1.21-2.62,1.83
                      c-0.18,0.02-0.35,0.06-0.53,0.15c-0.06,0.03-0.12,0.07-0.18,0.1c-0.27,0.05-0.53,0.18-0.78,0.42c-0.11,0.1-0.21,0.21-0.32,0.31
                      c-0.23,0.22-0.36,0.47-0.41,0.73c-0.12,0.08-0.25,0.18-0.42,0.3c-0.58,0.41-0.74,1.02-0.63,1.58c-0.33,0.32-0.66,0.63-0.99,0.95
                      C8.7,16.34,9.07,15.13,9.64,14.1z M7.9,25.63C7.86,25.82,7.88,26,7.92,26.18c-0.04,0.06-0.08,0.12-0.12,0.17
                      c0-0.21-0.01-0.42-0.01-0.62C7.83,25.7,7.86,25.66,7.9,25.63z M8.1,30.13c0.05,0.06,0.1,0.11,0.16,0.17
                      c-0.04,0.05-0.08,0.1-0.12,0.15C8.13,30.35,8.12,30.24,8.1,30.13z M9.95,35.62c0.47-0.56,0.94-1.11,1.42-1.67
                      c0.21-0.2,0.41-0.4,0.62-0.6c-0.04,0.55,0.29,1.03,0.77,1.29c0.31,0.25,0.7,0.39,1.09,0.35c-0.03,0.51,0.26,0.93,0.69,1.19
                      c-0.47,0.7-0.96,1.38-1.42,2.09C11.75,37.74,10.72,36.81,9.95,35.62z M26.72,39.48c0.21-0.18,0.42-0.35,0.63-0.53
                      c0.01,0.2,0.06,0.38,0.15,0.53C27.25,39.48,26.98,39.48,26.72,39.48z M30.15,39.53c0.31-0.28,0.61-0.57,0.92-0.85
                      c0.08,0.06,0.16,0.11,0.25,0.16c-0.19,0.24-0.37,0.48-0.56,0.71C30.55,39.54,30.35,39.54,30.15,39.53z M40.47,33.44
                      c-1.55,2.05-3.07,4.11-4.56,6.2c-0.28,0-0.56-0.01-0.84-0.01C36.86,37.57,38.66,35.51,40.47,33.44z M54.17,38.21
                      c-0.1,0.19-0.2,0.37-0.3,0.56c-0.11,0.21-0.16,0.43-0.17,0.64c-0.25,0.01-0.5,0.02-0.76,0.03C53.35,39.03,53.76,38.62,54.17,38.21z
                      M68.66,38.72c0.47-0.44,0.93-0.88,1.4-1.32c0.05,0.02,0.11,0.03,0.16,0.04c-0.37,0.42-0.73,0.84-1.1,1.25
                      C68.96,38.71,68.81,38.71,68.66,38.72z M88.7,37.52c0.14-0.1,0.27-0.2,0.4-0.31c-0.09,0.1-0.17,0.2-0.26,0.3
                      C88.8,37.52,88.75,37.52,88.7,37.52z M94.45,37.01c0.01,0.06,0.02,0.12,0.03,0.17c-0.08,0-0.15,0.01-0.23,0.01
                      C94.32,37.14,94.39,37.08,94.45,37.01z M120.29,36.22c0.42-0.43,0.83-0.86,1.25-1.3c0.14,0.15,0.32,0.28,0.51,0.36
                      c0.46,0.78,1.51,1.2,2.27,0.32c1.01-1.17,2.02-2.34,3.03-3.51c0.27,0.03,0.55,0,0.78-0.11c-1.25,1.5-2.53,2.97-3.83,4.42
                      C122.92,36.35,121.56,36.26,120.29,36.22z M135.2,31.49c-0.1,0.04-0.19,0.08-0.28,0.15c-0.02,0.02-0.05,0.03-0.07,0.05
                      c0.14-0.22,0.27-0.44,0.41-0.66C135.24,31.18,135.23,31.34,135.2,31.49z"
            />
          </svg>
          <span className="button-text">{buttonText}</span>
        </div>
      )}
    </Link>
  );
}

export default ButtonLink;
