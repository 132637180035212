import React from "react";
import "../App.scss";
import { motorola5GBattleProject } from "../Content.js";

function Bonus2() {
  return (
    <div
      id="bonus-2"
      alt={motorola5GBattleProject.headlineMain1}
      className="elena-font-black"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1034.52 200.28">
        <polygon
          class="cls-1"
          points="668.94 61.39 668.94 70.12 721.3 70.12 721.3 96.3 625.3 96.3 625.3 35.2 677.66 35.2 677.66 26.48 625.3 26.48 625.3 0.29 721.3 0.29 721.3 61.39 668.94 61.39"
        />
        <path
          class="cls-1"
          d="M96.52,45.68V.68H.52v96h96v-43h-9v-8Zm-43,24h-8v-8h8Zm0-34h-8v-8h8Z"
        />
        <path
          class="cls-1"
          d="M209,104.21v26.18h52.36v8.72H209v61.1h96v-96ZM261.36,174h-8.73V165.3h8.73Z"
        />
        <rect class="cls-2" x="730" y="86.21" width="10" height="10" />
        <rect class="cls-2" x="730" y="0.21" width="10" height="10" />
        <polygon
          class="cls-2"
          points="410 104.21 410 200.21 314 200.21 314 174.21 367 174.21 367 165.21 314 165.21 314 104.21 358.5 104.21 358.5 129.71 367.5 129.71 367.5 104.21 410 104.21"
        />
        <polygon
          class="cls-2"
          points="826 104.21 826 200.21 730 200.21 730 104.21 773.63 104.21 773.63 156.57 782.36 156.57 782.36 104.21 826 104.21"
        />
        <polygon
          class="cls-2"
          points="410 0.21 410 96.21 314 96.21 314 0.21 357.63 0.21 357.63 52.57 366.36 52.57 366.36 0.21 410 0.21"
        />
        <polygon
          class="cls-2"
          points="462 26.21 462 35.21 514 35.21 514 96.21 418 96.21 418 70.02 470.36 70.02 470.36 61.3 418 61.3 418 0.21 514 0.21 514 26.21 462 26.21"
        />
        <path
          class="cls-2"
          d="M617.52,156.21v-52h-96v96h46v-20h9v20h41v-35h-9v-9Zm-43-8h-9v-9h9Z"
        />
        <path
          class="cls-2"
          d="M0,104.21v96H69.5v-23.5H96v-72.5Zm53,44H44v-9h9Z"
        />
        <path
          class="cls-2"
          d="M626,104.21v96h96v-96Zm52.3,52.29h-8.6v-8.59h8.6Z"
        />
        <path
          class="cls-2"
          d="M104.21,0l-.42,96,96,.42.42-96Zm52.07,52.52-8.6,0,0-8.59,8.6,0Z"
        />
        <polygon
          class="cls-2"
          points="929.97 104.21 930.03 200.21 868.95 200.25 868.93 174.06 860.2 174.07 860.22 200.26 834.03 200.28 833.97 104.28 895.05 104.24 895.07 130.42 903.8 130.41 903.78 104.23 929.97 104.21"
        />
        <polygon
          class="cls-2"
          points="304.96 0.21 305.04 96.21 243.94 96.25 243.93 70.06 235.2 70.07 235.22 96.26 209.03 96.28 208.97 0.28 270.06 0.24 270.07 26.42 278.8 26.41 278.79 0.23 304.96 0.21"
        />
        <polygon
          class="cls-2"
          points="200 174.02 200 200.21 104 200.21 104 104.21 173.82 104.21 173.82 174.02 200 174.02"
        />
        <polygon
          class="cls-2"
          points="461.5 139.71 461.5 165.71 470.5 165.71 470.5 147.84 514 147.84 514 200.21 418 200.21 418 104.21 514 104.21 514 139.11 461.63 139.11 461.63 139.71 461.5 139.71"
        />
        <path
          class="cls-2"
          d="M1010.27,104.21H938.52v96h76.61l19.39-19.39V128.45Zm-18.75,53h-9v-9h9Z"
        />
        <rect class="cls-2" x="521.57" y="39.46" width="96" height="26.04" />
      </svg>
    </div>
  );
}

export default Bonus2;
