import React from "react";
import "../App.scss";

function LogoSass() {
  return (
    <div id="logo-sass">
      <svg id="sass" viewBox="0 0 100 100" fill="#c69">
        <circle cx="50" cy="50" r="50"></circle>
        <path
          d="M40.06 66.65c0.73 2.69 0.65 5.2 -0.1 7.47 -0.08 0.25 -0.18 0.5 -0.28 0.75 -0.1 0.25 -0.21 0.49 -0.32 0.73 -0.58 1.2 -1.36 2.33 -2.31 3.37 -2.91 3.17 -6.96 4.37 -8.7 3.36 -1.88 -1.09 -0.94 -5.56 2.43 -9.12 3.62 -3.83 8.84 -6.29 8.84 -6.29l-0.01 -0.01c0.15 -0.08 0.3 -0.16 0.46 -0.25zm41.3 -45.25c-2.26 -8.88 -16.99 -11.8 -30.92 -6.85 -8.29 2.94 -17.27 7.57 -23.73 13.61 -7.68 7.18 -8.9 13.43 -8.39 16.04 1.78 9.21 14.4 15.24 19.59 19.7l0 0.03C36.38 64.68 25.18 70.35 22.56 76.14 19.79 82.26 23 86.64 25.12 87.23c6.58 1.83 13.33 -1.46 16.95 -6.87 3.5 -5.22 3.21 -11.96 1.69 -15.31 2.1 -0.55 4.54 -0.8 7.65 -0.44 8.77 1.02 10.49 6.5 10.16 8.79 -0.33 2.29 -2.17 3.55 -2.78 3.93 -0.62 0.38 -0.8 0.51 -0.75 0.8 0.08 0.41 0.36 0.4 0.88 0.31 0.72 -0.12 4.6 -1.86 4.77 -6.09 0.21 -5.37 -4.94 -11.38 -14.05 -11.22 -3.75 0.07 -6.11 0.42 -7.82 1.06 -0.13 -0.14 -0.25 -0.29 -0.39 -0.43 -5.63 -6.01 -16.05 -10.26 -15.61 -18.35 0.16 -2.94 1.18 -10.67 20.02 -20.06 15.43 -7.69 27.78 -5.57 29.91 -0.88 3.05 6.7 -6.6 19.15 -22.62 20.94 -6.1 0.68 -9.32 -1.68 -10.12 -2.56 -0.84 -0.93 -0.97 -0.97 -1.28 -0.8 -0.51 0.28 -0.19 1.1 0 1.59 0.48 1.24 2.44 3.45 5.79 4.55 2.94 0.97 10.11 1.5 18.78 -1.85 9.71 -3.76 17.29 -14.2 15.06 -22.93"
          fill="#ffffff"
        ></path>
      </svg>
    </div>
  );
}

export default LogoSass;
