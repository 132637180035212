import React from "react";
import "../App.scss";
import { aPitProject } from "../Content.js";

function APitDesignSystemHeadline3Lines() {
  return (
    <div
      id="apit-design-system-headline-3-lines"
      alt={aPitProject.headlineMain1}
      className="elena-font-black"
    >
      <svg version="1.1" viewBox="0 0 930 304">
        <polygon
          class="st0"
          points="147.6,130.2 147.6,138.9 200,138.9 200,165.1 147.6,165.1 147.6,173.8 200,173.8 200,200 104,200 
	104,104 200,104 200,130.2 "
        />
        <polygon
          class="st0"
          points="461.1,234.2 461.1,242.9 513.5,242.9 513.5,269.1 461.1,269.1 461.1,277.8 513.5,277.8 513.5,304 
	417.5,304 417.5,208 513.5,208 513.5,234.2 "
        />
        <polygon
          class="st0"
          points="200,0 200,96 138.9,96 138.9,69.8 130.2,69.8 130.2,96 104,96 104,0 165.1,0 165.1,26.2 173.8,26.2 
	173.8,0 "
        />
        <polygon
          class="st0"
          points="565,104 565,200 503.9,200 503.9,173.8 495.2,173.8 495.2,200 469,200 469,104 530.1,104 530.1,130.2 
	538.8,130.2 538.8,104 "
        />
        <path
          class="st0"
          d="M0,0v26.2h52.4v8.7H0V96h96V0H0z M52.4,69.8h-8.7v-8.7h8.7V69.8z"
        />
        <path
          class="st0"
          d="M416,0v96h96V0H416z M468.4,52.4h-8.7v-8.7h8.7V52.4z"
        />
        <rect x="520" class="st0" width="45" height="96" />
        <rect x="312" y="104" class="st0" width="45" height="96" />
        <rect x="781" class="st0" width="45" height="96" />
        <path
          class="st0"
          d="M279.8,0H208v96h76.6L304,76.6V24.2L279.8,0z M261,53h-9v-9h9V53z"
        />
        <path
          class="st0"
          d="M71.8,104H0v96h76.6L96,180.6v-52.4L71.8,104z M53,157h-9v-9h9V157z"
        />
        <path
          class="st0"
          d="M644.8,0H573v96h76.6L669,76.6V24.2L644.8,0z M626,53h-9v-9h9V53z"
        />
        <path
          class="st0"
          d="M408,52V0h-96v96h46V76h9v20h41V61h-9v-9H408z M365,44h-9v-9h9V44z"
        />
        <path
          class="st0"
          d="M677,0v96h69.5V72.5H773V0H677z M730,44h-9v-9h9V44z"
        />
        <polygon
          class="st0"
          points="930,0 930,26 904,26 904,96 860,96 860,26 834,26 834,0 "
        />
        <polygon
          class="st0"
          points="409.5,208 409.5,234 383.5,234 383.5,304 339.5,304 339.5,234 313.5,234 313.5,208 "
        />
        <polygon
          class="st0"
          points="252,130 252,139 304,139 304,200 208,200 208,173.8 260.4,173.8 260.4,165.1 208,165.1 208,104 
	304,104 304,130 "
        />
        <polygon
          class="st0"
          points="44.5,234 44.5,243 96.5,243 96.5,304 0.5,304 0.5,277.8 52.9,277.8 52.9,269.1 0.5,269.1 0.5,208 
	96.5,208 96.5,234 "
        />
        <polygon
          class="st0"
          points="253.5,234 253.5,243 305.5,243 305.5,304 209.5,304 209.5,277.8 261.9,277.8 261.9,269.1 209.5,269.1 
	209.5,208 305.5,208 305.5,234 "
        />
        <polygon
          class="st0"
          points="200.5,208 200.5,304 104.5,304 104.5,278 157.5,278 157.5,269 104.5,269 104.5,208 149,208 149,233.5 
	158,233.5 158,208 "
        />
        <polygon
          class="st0"
          points="617.5,208 617.5,304 592,304 592,278.5 583,278.5 583,304 556,304 556,278.5 547,278.5 547,304 
	521.5,304 521.5,208 565.5,208 565.5,217 574.5,217 574.5,208 "
        />
        <polygon
          class="st0"
          points="408.5,139.5 408.5,165.5 417.5,165.5 417.5,147.6 461,147.6 461,200 365,200 365,104 461,104 
	461,138.9 408.6,138.9 408.6,139.5 "
        />
      </svg>
    </div>
  );
}

export default APitDesignSystemHeadline3Lines;
