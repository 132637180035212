import React from "react";
import "../App.scss";
import { aPitProject } from "../Content.js";

function APitDesignSystemHeadline() {
  return (
    <div id="apit-design-system-headline" alt={aPitProject.headlineMain1} className="elena-font-black">
      <svg viewBox="0 0 1243 200" version="1.1">
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Artboard-Copy"
            transform="translate(0.000000, -296.000000)"
            fill="#313131"
          >
            <g id="Group-2" transform="translate(0.000000, 296.000000)">
              <path
                d="M200,173.818182 L200,200 L104,200 L104,104 L200,104 L200,130.181818 L147.636364,130.181818 L147.636364,138.909091 L200,138.909091 L200,165.090909 L147.636364,165.090909 L147.636364,173.818182 L200,173.818182 Z"
                id="Combined-Shape-Copy-7"
              ></path>
              <path
                d="M1139,173.818182 L1139,200 L1043,200 L1043,104 L1139,104 L1139,130.181818 L1086.63636,130.181818 L1086.63636,138.909091 L1139,138.909091 L1139,165.090909 L1086.63636,165.090909 L1086.63636,173.818182 L1139,173.818182 Z"
                id="Combined-Shape-Copy-13"
              ></path>
              <path
                d="M130.181818,96 L104,96 L104,0 L165.090909,0 L165.090909,26.1818182 L173.818182,26.1818182 L173.818182,3.41060513e-13 L200,0 L200,96 L138.909091,96 L138.909091,69.8181818 L130.181818,69.8181818 L130.181818,96 Z"
                id="Combined-Shape-Copy-2"
              ></path>
              <path
                d="M495.181818,200 L469,200 L469,104 L530.090909,104 L530.090909,130.181818 L538.818182,130.181818 L538.818182,104 L565,104 L565,200 L503.909091,200 L503.909091,173.818182 L495.181818,173.818182 L495.181818,200 Z"
                id="Combined-Shape-Copy-9"
              ></path>
              <path
                d="M0,34.9090909 L52.3636364,34.9090909 L52.3636364,26.1818182 L0,26.1818182 L0,0 L96,0 L96,96 L0,96 L0,34.9090909 Z M43.6363636,61.0909091 L43.6363636,69.8181818 L52.3636364,69.8181818 L52.3636364,61.0909091 L43.6363636,61.0909091 Z"
                id="Combined-Shape-Copy-3"
              ></path>
              <path
                d="M416,0 L512,0 L512,96 L416,96 L416,0 Z M459.636364,43.6363636 L459.636364,52.3636364 L468.363636,52.3636364 L468.363636,43.6363636 L459.636364,43.6363636 Z"
                id="Combined-Shape-Copy-4"
              ></path>
              <path
                d="M565,0 L565,96 L520,96 L520,0 L565,0 Z"
                id="Combined-Shape-Copy-5"
              ></path>
              <path
                d="M357,104 L357,200 L312,200 L312,104 L357,104 Z"
                id="Combined-Shape-Copy-8"
              ></path>
              <path
                d="M826,0 L826,96 L781,96 L781,0 L826,0 Z"
                id="Combined-Shape-Copy-6"
              ></path>
              <g id="Group" transform="translate(208.000000, 0.000000)">
                <path
                  d="M76.6095237,96 L0,96 L0,0 L71.7541126,0 L96,24.2458874 L96,76.6095237 L76.6095237,96 Z M44,44 L44,53 L53,53 L53,44 L44,44 Z"
                  id="Combined-Shape"
                ></path>
              </g>
              <g id="Group-Copy" transform="translate(0.000000, 104.000000)">
                <path
                  d="M76.6095237,96 L0,96 L0,0 L71.7541126,0 L96,24.2458874 L96,76.6095237 L76.6095237,96 Z M44,44 L44,53 L53,53 L53,44 L44,44 Z"
                  id="Combined-Shape"
                ></path>
              </g>
              <g id="Group-Copy-2" transform="translate(573.000000, 0.000000)">
                <path
                  d="M76.6095237,96 L0,96 L0,0 L71.7541126,0 L96,24.2458874 L96,76.6095237 L76.6095237,96 Z M44,44 L44,53 L53,53 L53,44 L44,44 Z"
                  id="Combined-Shape"
                ></path>
              </g>
              <path
                d="M408,61 L408,96 L367,96 L367,76 L358,76 L358,96 L312,96 L312,0 L408,0 L408,52 L399,52 L399,61 L408,61 Z M356,35 L356,44 L365,44 L365,35 L356,35 Z"
                id="Combined-Shape"
              ></path>
              <path
                d="M773,72.5 L746.5,72.5 L746.5,96 L677,96 L677,0 L773,0 L773,72.5 Z M721,35 L721,44 L730,44 L730,35 L721,35 Z"
                id="Combined-Shape"
              ></path>
              <path
                d="M904,96 L834,96 L834,61 L834,26 L860,26 L860,96 L834,96 L834,61 L834,0 L930,0 L930,26 L904,26 L904,96 Z"
                id="Combined-Shape"
              ></path>
              <path
                d="M1009,200 L939,200 L939,165 L939,130 L965,130 L965,200 L939,200 L939,165 L939,104 L1035,104 L1035,130 L1009,130 L1009,200 Z"
                id="Combined-Shape-Copy-12"
              ></path>
              <path
                d="M304,130 L252,130 L252,139 L304,139 L304,200 L208,200 L208,173.818182 L260.363636,173.818182 L260.363636,165.090909 L208,165.090909 L208,104 L304,104 L304,130 Z"
                id="Combined-Shape"
              ></path>
              <path
                d="M722,130 L670,130 L670,139 L722,139 L722,200 L626,200 L626,173.818182 L678.363636,173.818182 L678.363636,165.090909 L626,165.090909 L626,104 L722,104 L722,130 Z"
                id="Combined-Shape-Copy-10"
              ></path>
              <path
                d="M931,130 L879,130 L879,139 L931,139 L931,200 L835,200 L835,173.818182 L887.363636,173.818182 L887.363636,165.090909 L835,165.090909 L835,104 L931,104 L931,130 Z"
                id="Combined-Shape-Copy-11"
              ></path>
              <path
                d="M730,165 L730,104 L774.5,104 L774.5,129.5 L783.5,129.5 L783.5,104 L826,104 L826,200 L730,200 L730,174 L783,174 L783,165 L730,165 Z"
                id="Combined-Shape"
              ></path>
              <path
                d="M1217.5,200 L1217.5,174.5 L1208.5,174.5 L1208.5,200 L1181.5,200 L1181.5,174.5 L1172.5,174.5 L1172.5,200 L1147,200 L1147,104 L1191,104 L1191,113 L1200,113 L1200,104 L1243,104 L1243,200 L1217.5,200 Z"
                id="Combined-Shape"
              ></path>
              <path
                d="M408.636364,139.5 L408.5,139.5 L408.5,165.5 L417.5,165.5 L417.5,147.636364 L461,147.636364 L461,200 L365,200 L365,104 L461,104 L461,138.909091 L408.636364,138.909091 L408.636364,139.5 Z"
                id="Combined-Shape"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default APitDesignSystemHeadline;
